import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'JeShanghai',
        [
            {
                name: 'AccountNumber',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Credit',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Debit',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Amount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Date',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'LineMemo',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Memo',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Name',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ReversalDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'Subsidiary',
                type: FIELD_UNITS.STRING
            }
        ],
        {}, //options
        (data) => {
            console.log('JE Sanghai REDUCER-- ', data);
        }
    );
}

const JeShanghai = {
    configureSetupCall
};

export default JeShanghai;
