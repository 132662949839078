import validator from 'validator';

/**
 *
 * @param {*} value Injected by react-validation
 */
const required = (value) => {
    if (!(value || '').toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return 'This field is required';
    }
};

const lettersOnly = (value) => {
    if (value) {
        const val = value.match(/^[A-Za-z]+$/);
        if (!Boolean(val)) {
            // components['password'][0].value !== components['confirm'][0].value
            // 'confirm' - name of input
            // components['confirm'] - array of same-name components because of checkboxes and radios
            return 'This field must only consist of letters.';
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Email validator
 */
const email = (value) => {
    if (!validator.isEmail(value)) {
        return `${value} is not a valid email.`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Email validator
 */
const emailsAsCsv = (value) => {
    let emailArr = (value || '')
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email);
    for (let i = 0; i < emailArr.length; i++) {
        if (!validator.isEmail(emailArr[i])) {
            return `${emailArr[i]} is not a valid email.`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Email validation for driscollsEmail
 */
const driscollsEmail = (value) => {
    const val = value.match(/^.*@driscolls\.com/);
    if (!Boolean(val)) {
        return 'Email must be Driscolls.';
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * Anything other than driscolls
 */
const nonDriscollsEmail = (value) => {
    const val = value.match(/^.*@driscolls\.com/);
    if (Boolean(val)) {
        return 'Email cannot be Driscolls.';
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * alphanumeric value check
 */
const alphaNumeric = (value) => {
    if (value) {
        let regex = new RegExp('^[\x00-\x7F]*$');
        const val = value.match(regex);
        if (!Boolean(val)) {
            return `Unicode based special characters are not allowed`;
        }
    }
};

const emptyBinIdCheck = (value) => {
    if (value) {
        const val = value.match(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
        if (!Boolean(val)) {
            return `Only letters, numbers and spaces are allowed for this field.`;
        }
    }
};

const binIdCheck = (value) => {
    if (value) {
        const val = value.match(/^[A-Za-z0-9]*[A-Za-z0-9][A-Za-z0-9]*$/);
        if (!Boolean(val)) {
            return `Only letters and numbers are allowed for this field.`;
        }
    }
};
/**
 *
 * @param {*} value Injected by react-validation
 * numeric value check no other symbols
 */
const numeric = (value) => {
    if (value) {
        const val = String(value).match(/^[0-9]+$/);
        if (!Boolean(val)) {
            return `Only numbers are allowed in this field.`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * numeric value check with decimal no other symbols
 */
const decimal = (value) => {
    if (value) {
        const val = String(value).match(/^[0-9.]+$/);
        if (!Boolean(val)) {
            return `Only numbers and decimals are allowed in this field.`;
        }
    }
};

const decimalWithFixedLength = (value) => {
    if (value) {
        let isDecimalError = decimal(value);
        if (isDecimalError) {
            return isDecimalError;
        } else {
            return !/^(?:\d{0,8}\.\d{1,3}|\d{0,8})$/.test(value) ? `Only 8 digits allowed with 3 decimal` : '';
        }
    }
};
/**
 *
 * @param {*} value Injected by react-validation
 * numeric value check no other symbols
 */
const decimalWithLiter = (value) => {
    if (value) {
        const val = String(value).match(/^[0-9.L]+$/);
        if (!Boolean(val)) {
            return `Only numbers, decimals and 'L' are allowed in this field.`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * numeric value check with decimal or negative value no other symbols
 */
const integer = (value) => {
    if (value) {
        const val = String(value).match(/^[0-9.-]+$/);
        if (!Boolean(val)) {
            return `Only integers are allowed in this field.`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * numeric value checks decimal with +- symbols
 */
const decimalWithSign = (value) => {
    if (value) {
        const val = String(value).match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/);
        if (!Boolean(val)) {
            return `Only numbers and decimals are allowed in this field.`;
        }
    }
};

//positive integers including zero
const positiveInteger = (value, props) => {
    const val = props.required ? String(value).match(/^[0-9]+$/) : String(value).match(/^$|^[0-9]+$/);
    if (!Boolean(val)) {
        return `Only positive integers are allowed in this field.`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const maxlen = (value, props) => {
    // get the maxLength from component's props
    if (value) {
        if (value.toString().trim().length > parseInt(props.maxlen, 10)) {
            // Return jsx
            return `Only ${props.maxlen} characters are allowed for this field`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const haslength = (value, props) => {
    // get the maxLength from component's props
    if (value) {
        if (value.toString().trim().length !== parseInt(props.allowedlength, 10)) {
            // Return jsx
            return `Field should be ${props.allowedlength} characters long`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const maxValue = (value, props) => {
    if (parseFloat(value) > parseFloat(props.maxvalue)) {
        // Return jsx
        return `Maximum value allowed for the field is ${props.maxvalue}`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const minValue = (value, props) => {
    if (parseFloat(value) < parseFloat(props.minvalue)) {
        // Return jsx
        return `Minimum value allowed for the field is ${props.minvalue}`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const rangeValidator = (value, props) => {
    if (parseFloat(value) < parseFloat(props.minvalue) || parseFloat(value) > parseFloat(props.maxvalue)) {
        // Return jsx
        return `${props.fieldname} is outside acceptable range. Valid range is (${props.minvalue} to ${props.maxvalue})`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const maxIntValue = (value, props) => {
    if (parseInt(value) > parseInt(props.maxvalue)) {
        // Return jsx
        return `Maximum value allowed for the field is ${props.maxvalue}`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 */
const minintvalue = (value, props) => {
    if (parseInt(value) < parseInt(props.minvalue)) {
        // Return jsx
        return `Minimum value allowed for the field is ${props.minvalue}`;
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 * @param {*} props Injected by react-validation
 * @param {*} components
 */
const password = (value, props, components) => {
    // NOTE: Tricky place. The 'value' argument is always current component's value.
    // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
    // But if we're changing 'confirm' component - the condition will always be true
    // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
    if (value !== components['confirm'][0].value) {
        // components['password'][0].value !== components['confirm'][0].value
        // 'confirm' - name of input
        // components['confirm'] - array of same-name components because of checkboxes and radios
        return 'Passwords are not equal';
    }
};

const precision = (value, props) => {
    if (value) {
        let afterDecimal = ('' + value).split('.');
        if (afterDecimal[1] && afterDecimal[1].length > props.precision) {
            return `Only ${props.precision} decimal place allowed`;
        }
    }
};

/**
 *
 * @param {*} value Injected by react-validation
 */
const optionRequired = (value) => {
    if (!value) {
        return 'This field is required';
    }
};

export {
    required,
    email,
    maxlen,
    lettersOnly,
    password,
    alphaNumeric,
    driscollsEmail,
    nonDriscollsEmail,
    maxValue,
    minValue,
    maxIntValue,
    minintvalue,
    haslength,
    precision,
    numeric,
    decimal,
    decimalWithLiter,
    integer,
    decimalWithSign,
    positiveInteger,
    rangeValidator,
    binIdCheck,
    emptyBinIdCheck,
    optionRequired,
    decimalWithFixedLength,
    emailsAsCsv
};
