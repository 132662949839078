export const SET_CUSTOMER_CATEGORY_DATA = 'SET_CUSTOMER_CATEGORY_DATA';
export const setCustomerCategoryData = (data) => ({
    type: SET_CUSTOMER_CATEGORY_DATA,
    payload: data
});

export const CLEAR_CUSTOMER_CATEGORY_DATA = 'CLEAR_CUSTOMER_CATEGORY_DATA';
export const clearCustomerCategoryData = () => ({
    type: CLEAR_CUSTOMER_CATEGORY_DATA,
    payload: null
});
