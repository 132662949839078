import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const roleUtilities = (token) => {
    var allGroups = DuAuthenticationUtilities.GetGroups(token) || [];

    var allGgsAppGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_USER_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];
    var allAdminGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];
    var matchedGroups =
        allGroups.filter((group) => {
            return allGgsAppGroups.includes(group);
        }) || [];

    var hasAdminGroup = matchedGroups.find((group) => {
        return allAdminGroups.includes(group);
    });
    var roleName = '';

    /*     if (hasAdminGroup) {
        // roleName = 'Admin';
        roleName = hasAdminGroup;
    } else if (matchedGroups.length > 0) {
        roleName = matchedGroups[0];
    }

    if (roleName.includes('-')) {
        roleName = roleName.substring(roleName.indexOf('-') + 1);

        while (roleName.includes('-')) {
            roleName = roleName.replace('-', ' ');
        }
        return roleName;
    } */
    var userGgsGroups = matchedGroups.filter((group) => group.includes('GGS'));
    var readAbleGroups = userGgsGroups.map((group) => ({ group, display: (group.substring(group.indexOf('-') + 1) || '').split('-').join(' ') }));
    return readAbleGroups;
};

export const isRoleAdmin = (role) => {
    var allAdminGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];
    if (allAdminGroups.includes(role.group)) return true;
    return false;
};

export default roleUtilities;
