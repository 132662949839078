import { Middleware } from '@driscollsinc/one-ring';
const getGrowerCategoryMapping = [
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('GrowerName', 'GrowerName'),
    Middleware.CreateMapping('ChineseName', 'ChineseName'),
    Middleware.CreateMapping('VendorCode', 'VendorCode'),
    Middleware.CreateMapping('CustomerCode', 'CustomerCode'),
    Middleware.CreateMapping('Category', 'Category'),
    Middleware.CreateMapping('Geography', 'Geography'),
    Middleware.CreateMapping('FamilyEntity', 'FamilyEntity'),
    Middleware.CreateArrayMapping('Location', 'Location')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('GrowerCategory', 'GET')) {
        Middleware.RegisterMapping('GrowerCategory', 'GET', getGrowerCategoryMapping);
    }
}

const GrowerCategoryMappingMapping = {
    ConfigureMiddleware
};

export default GrowerCategoryMappingMapping;
