import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'PoolTable',
        [
            // Temporary Commenting these as they are crashing the app
            // {
            //     name: 'BusinessUnit',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'ModifiedDateTime',
            //     type: FIELD_UNITS.DATE_TIME
            // },
            // {
            //     name: 'CreatedBy',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'IncludeRebates',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'VarietySelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'PFQBasis',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'IncludeDiscounts',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'DestinationCountrySelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'Description',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'PoolTableID',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'DataareaID',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'VendorSelected',
            //     type: FIELD_UNITS.RAW
            // },
            // {
            //     name: 'RegionSelected',
            //     type: FIELD_UNITS.RAW
            // },
            // {
            //     name: 'ItemSelected',
            //     type: FIELD_UNITS.RAW
            // },
            // {
            //     name: 'Description',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'PoolStatus',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'SettlementType',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'PoolID',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'AveragePricingType',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'GrowerSelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'NumberOfPeriodOffset',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'CommoditySelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'RegionSelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'ParentPoolIDRec',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'DestCountySelected',
            //     type: FIELD_UNITS.RAW
            // },
            // {
            //     name: 'NestedSetLeft',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'StartingDay',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'IsPFQ',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'PoolDuration',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'ItemSelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'SettlementPeriod',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'ProducingAreaSelection',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'IncludeCharges',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'IsBlockOnReceiptLine',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'NestedSetRight',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'LevelID',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'ProducingAreaSelected',
            //     type: FIELD_UNITS.RAW
            // },
            // {
            //     name: 'ModifiedBy',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'CommoditySelected',
            //     type: FIELD_UNITS.STRING
            // },
            // {
            //     name: 'DisableChargePoolingInPFQ',
            //     type: FIELD_UNITS.NUMBER
            // },
            // {
            //     name: 'CreatedDateTime',
            //     type: FIELD_UNITS.DATE_TIME
            // }
        ],
        {}, //options
        (data) => {
            console.log('REDUCER-- ', data);
        }
    );
}

const PoolTableSetup = {
    configureSetupCall
};

export default PoolTableSetup;
