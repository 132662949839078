export const SET_SETTLEMENT_WORKBENCH_DATA = 'SET_SETTLEMENT_WORKBENCH_DATA';
export const setSettlementWorkbenchData = (data) => ({
    type: SET_SETTLEMENT_WORKBENCH_DATA,
    payload: data
});

export const CLEAR_SETTLEMENT_WORKBENCH_DATA = 'CLEAR_SETTLEMENT_WORKBENCH_DATA';
export const clearSettlementWorkbenchData = () => ({
    type: CLEAR_SETTLEMENT_WORKBENCH_DATA,
    payload: null
});
