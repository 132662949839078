import {
    currencyOptions,
    excludeAveragePricingOptions,
    geographyOptions,
    reportOptions,
    growerCategoryOptions,
    versionOptions
} from '../../data/constants';
import { maxlen, numeric, alphaNumeric } from '../../utils/validations';

export const filterFields = [
    {
        FieldName: 'SettlementCurrency',
        DisplayName: 'Settlement Currency',
        Datatype: 'string',
        QueryName: 'currency',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: currencyOptions
    },
    {
        FieldName: 'EstimateGroup',
        DisplayName: 'Select Estimate Group',
        Datatype: 'string',
        QueryName: 'estimategroup',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'Commodity',
        DisplayName: 'Commodity',
        Datatype: 'string',
        QueryName: 'commodity',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'ReportType',
        DisplayName: 'Report Type',
        Datatype: 'string',
        QueryName: 'reporttype',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: reportOptions
    },
    {
        FieldName: 'ReportTypeOptions',
        DisplayName: 'Report Type',
        Datatype: 'string',
        QueryName: 'reporttype',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: versionOptions
    },
    {
        FieldName: 'PoolWeek',
        DisplayName: 'Select Pool Week',
        Datatype: 'string',
        QueryName: 'poolweek',
        IsSearchable: true,
        hasOptions: true,
        isMulti: false,
        Options: []
    },
    {
        FieldName: 'CustomerCodeCategory',
        DisplayName: 'Customer Code Category',
        Datatype: 'string',
        QueryName: 'category',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'GrowerCategory',
        DisplayName: 'Grower Category',
        Datatype: 'string',
        QueryName: 'category',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'GrowerModule',
        DisplayName: 'Grower Module',
        Datatype: 'string',
        QueryName: 'module',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'Geography',
        DisplayName: 'Geography',
        Datatype: 'string',
        QueryName: 'geography',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: geographyOptions
    },
    {
        FieldName: 'FamilyEntity',
        DisplayName: 'Family Entity',
        Datatype: 'string',
        QueryName: 'familyentity',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'LocationCode',
        DisplayName: 'Location Code',
        Datatype: 'string',
        QueryName: 'locationcode',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'CoolerName',
        DisplayName: 'Cooler Name',
        Datatype: 'string',
        QueryName: 'coolername',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'Application',
        DisplayName: 'Application',
        Datatype: 'string',
        // QueryName: 'applicationfullname',
        QueryName: 'applicationnetsuite',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'PackageUOM',
        DisplayName: 'Package UOM',
        Datatype: 'string',
        QueryName: 'packageuom',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'FreightUOM',
        DisplayName: 'Freight UOM',
        Datatype: 'string',
        QueryName: 'freightuom',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        Options: []
    },
    {
        FieldName: 'ExcludeAveragePricing',
        DisplayName: 'Exclude Average Pricing',
        Datatype: 'string',
        QueryName: 'excludeaveragepricing',
        IsSearchable: true,
        hasOptions: true,
        isMulti: true,
        // isCheckbox: true,
        Options: excludeAveragePricingOptions
    },
    {
        FieldName: 'ItemID',
        DisplayName: 'Enter Item ID',
        Datatype: 'string',
        QueryName: 'itemid',
        IsSearchable: true,
        IsAsync: false,
        validations: [maxlen, numeric],
        customProps: { maxlen: 6 }
    },
    {
        FieldName: 'InvoiceID',
        DisplayName: 'Enter Invoice ID',
        Datatype: 'string',
        QueryName: 'invoiceid',
        IsSearchable: true,
        IsAsync: false,
        validations: [maxlen, alphaNumeric],
        customProps: { maxlen: 20 }
    },
    {
        FieldName: 'GrowerID',
        DisplayName: 'Grower ID',
        Datatype: 'string',
        QueryName: 'growerid',
        IsSearchable: true,
        IsAsync: false,
        hasOptions: true,
        options: []
    },
    {
        FieldName: 'RepackageItemId',
        DisplayName: 'Repackage Item Id',
        Datatype: 'string',
        QueryName: 'repackageitemid',
        IsSearchable: true,
        IsAsync: false,
        validations: [maxlen, numeric],
        customProps: { maxlen: 6 }
    },
    {
        FieldName: 'OriginalItemId',
        DisplayName: 'Original Item Id',
        Datatype: 'string',
        QueryName: 'originalitemid',
        IsSearchable: true,
        IsAsync: false,
        validations: [maxlen, numeric],
        customProps: { maxlen: 6 }
    },
    {
        FieldName: 'CustomerCode',
        DisplayName: 'Customer Code',
        Datatype: 'string',
        QueryName: 'customercode',
        IsSearchable: true,
        IsAsync: false,
        customProps: { maxlen: 100 }
    },
    {
        FieldName: 'GrowerName',
        DisplayName: 'Grower Name',
        Datatype: 'string',
        QueryName: 'growername',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'VendorCode',
        DisplayName: 'Vendor Code',
        Datatype: 'string',
        QueryName: 'vendorcode',
        IsSearchable: true,
        IsAsync: false,
        hasOptions: true,
        options: []
    },
    {
        FieldName: 'Country',
        DisplayName: 'Country',
        Datatype: 'string',
        QueryName: 'country',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'State',
        DisplayName: 'State',
        Datatype: 'string',
        QueryName: 'state',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'LookupType',
        DisplayName: 'Lookup Type',
        Datatype: 'string',
        QueryName: 'lookuptype',
        IsSearchable: true,
        IsAsync: false,
        hasOptions: true,
        Options: []
    },
    {
        FieldName: 'LookupCode',
        DisplayName: 'Lookup Code',
        Datatype: 'string',
        QueryName: 'lookupcode',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'Category',
        DisplayName: 'Category',
        Datatype: 'string',
        QueryName: 'category',
        IsSearchable: true,
        IsAsync: false,
        hasOptions: true,
        isMulti: true,
        Options: growerCategoryOptions
    },
    {
        FieldName: 'ShipmentNumber',
        DisplayName: 'Shipment Number',
        Datatype: 'string',
        QueryName: 'shipmentnumber',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'ContainerNumber',
        DisplayName: 'Container Number',
        Datatype: 'string',
        QueryName: 'containernumber',
        IsSearchable: true,
        IsAsync: false
    },
    {
        FieldName: 'ReceiptId',
        DisplayName: 'Enter Receipt Id',
        Datatype: 'string',
        QueryName: 'receiptid',
        IsSearchable: true,
        IsAsync: false
    }
];
