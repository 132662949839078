import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'SettlementRecord',
        [
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PaymentDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'ReportType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TotalKilosReceived',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FreightRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PackageDeduction',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrossPoolAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'CommissionNetPoolReturn',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FreightDeduction',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrowerSettlementID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'CommissionNetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AveragePricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VATNetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PackageRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrowerID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'UOMConversionRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VATNetPoolReturn',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AveragePricePerTray',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VATPercent',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'TotalTraysReceived',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Version',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'VATShareGrower',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'CommissionRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'SettlementCurrency',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'NetFruitBeforeDAPshare',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DAPShare',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Settlement Record REDUCER-- ', data);
        }
    );
}

const SettlementRecord = {
    configureSetupCall
};

export default SettlementRecord;
