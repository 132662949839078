import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'CustomerCodeCategory',
        [
            {
                name: 'CustomerCodeMapID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CustomerCode',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ExcludeSettlementPriceAmt',
                type: FIELD_UNITS.BOOLEAN
            },
            {
                name: 'ExcludeSettlementPriceQty',
                type: FIELD_UNITS.BOOLEAN
            },
            {
                name: 'ExcludeNormalSalesQty',
                type: FIELD_UNITS.BOOLEAN
            },
            {
                name: 'ExcludeNormalSalesAmt',
                type: FIELD_UNITS.BOOLEAN
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Customer Code Category REDUCER-- ', data);
        }
    );
}

const CustomerCodeCategory = {
    configureSetupCall
};

export default CustomerCodeCategory;
