import { SetupCall } from '@driscollsinc/driscolls-display-rules';
import { LOOKUP_FIELDS } from '../constants';
import { lookupSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        LOOKUP_FIELDS.CoolerNameLookUp,
        [...lookupSetupFields],
        {}, //options
        (data) => {
            console.log('CoolerNameLookUp REDUCER-- ', data);
        }
    );
}

const CoolerNameLookUp = {
    configureSetupCall
};

export default CoolerNameLookUp;
