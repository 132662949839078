import { Middleware } from '@driscollsinc/one-ring';
const getSettlementRateMapping = [
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('Location', 'Location'),
    Middleware.CreateMapping('CoolerName', 'CoolerName'),
    Middleware.CreateMapping('Application', 'Application'),
    Middleware.CreateMapping('ApplicationInNetSuite', 'ApplicationInNetSuite'),
    Middleware.CreateMapping('MarketingEntityCommissionRate', 'MarketingEntityCommissionRate'),
    Middleware.CreateMapping('ShareDriscollsFarming', 'ShareDriscollsFarming'),
    Middleware.CreateMapping('ShareGrower', 'ShareGrower'),
    Middleware.CreateMapping('MidsizeDriscollsFarming', 'MidsizeDriscollsFarming'),
    Middleware.CreateMapping('MidsizeGrower', 'MidsizeGrower'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SettlementRate', 'GET')) {
        Middleware.RegisterMapping('SettlementRate', 'GET', getSettlementRateMapping);
    }
}

const SettlementRateMapping = {
    ConfigureMiddleware
};

export default SettlementRateMapping;
