import { Middleware } from '@driscollsinc/one-ring';
const getStatusMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('PricingPool', 'PricingPool'),
    Middleware.CreateMapping('PoolId', 'PoolId'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('ExpectedQuantity', 'ExpectedQuantity'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('WeeklySettlementStatus', 'GET')) {
        Middleware.RegisterMapping('WeeklySettlementStatus', 'GET', getStatusMapping);
    }
}

const WeeklySettlementStatusMapping = {
    ConfigureMiddleware
};

export default WeeklySettlementStatusMapping;
