import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'Qujing',
        [
            {
                name: 'AccountNumber',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Debit',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Credit',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'LineMemo',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'AccountName',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Subsidiary',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'CostCenter',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.RAW
            },

        ],
        {}, //options
        (data) => {
            console.log('REDUCER-- ', data);
        }
    );
}

const Qujing = {
    configureSetupCall
};

export default Qujing;
