import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'SalesInvoiceLines',
        [
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CommissionPercent',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ContractID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'CustomerCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CustomerGeography',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CustomerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'DataareaID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'FixedFeeCostAmount',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InventDimID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InventTransID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InvoiceAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'InvoiceDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'InvoiceID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ItemDescription',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ItemRate',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LineNum',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Memo',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ModifiedDateTime',
                type: FIELD_UNITS.DATE_TIME
            },
            {
                name: 'Partitions',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PickupLocation',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Posted',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PricingBasis',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PricingPoolInstanceID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Quantity',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'RecID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReceiptID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReceiptLineRecID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Recversion',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SalesID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'SettlementAvgUnitPrice',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementPoolID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'VendorID',
                type: FIELD_UNITS.RAW
            }
        ],
        {}, //options
        (data) => {
            console.log('Sales Invoice Lines REDUCER-- ', data);
        }
    );
}

const SalesInvoiceLines = {
    configureSetupCall
};

export default SalesInvoiceLines;
