import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'ReceiptLines',
        [
            {
                name: 'AdditionalPallet',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'AdvanceAmount',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'AdvanceRefRecID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'AdvanceStatus',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'AdvancesPosted',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Amount',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Application',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BlockID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ByRecRef',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Ccmlinetype',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ContractID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'CostCenter',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'DataareaID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'DefaultDimension',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'DistinctProductionVariant',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'FirstSuffixID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'FixedPurchasePrice',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InterCompany',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InterCompanyGrower',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InventDimID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InventDimIDCov',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'InventTransID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'IsTempNegativeAdjustment',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'JournalTransRefRecID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LedgerDimension',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LineNum',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LineType',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LoadFailureStatus',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Location',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Memo',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ModifiedDateTime',
                type: FIELD_UNITS.DATE_TIME
            },
            {
                name: 'OriginalQty',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PFQAverageType',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PFQFactor',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PFQScore',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PFQUnitPrice',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PackageDeductBOMID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PackageDeducted',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PalletPlaceType',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PartiallySettled',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Partitions',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Posted',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ProductName',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'QCScore',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'QtyOrdered',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Quantity',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'RanchCreditStatus',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReasonCode',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'RecID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReceiptID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ReceiptLineLotCodeRequired',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReceiptSelected',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Recversion',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReservedQty',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SecondSuffixID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementAvgUnitPrice',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementCommissionAmt',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementLineAmount',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementNetAmount',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementPoolID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SettlementPoolIDOpen',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SoldQty',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Submitted',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TaxGroup',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TaxGroupCommission',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TaxItemGroup',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TransDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'UnitID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Variety',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'VendorID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'VendorName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'VendorNameChinese',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ExpectedQuantity',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ReceiptQuantity',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SalesAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            }
        ],
        {}, //options
        (data) => {
            console.log('Receipt Lines REDUCER-- ', data);
        }
    );
}

const ReceiptLines = {
    configureSetupCall
};

export default ReceiptLines;
