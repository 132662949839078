import { Middleware } from '@driscollsinc/one-ring';
const getWorkbenchMapping = [
    Middleware.CreateMapping('Reversed', 'Reversed'),
    Middleware.CreateMapping('SettlementPoolId', 'SettlementPoolId'),
    Middleware.CreateMapping('PricingPoolDefinition', 'PricingPoolDefinition'),
    Middleware.CreateMapping('SettlementPoolDefinition', 'SettlementPoolDefinition'),
    Middleware.CreateMapping('FromDate', 'FromDate'),
    Middleware.CreateMapping('ToDate', 'ToDate'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SettlementWorkbench', 'GET')) {
        Middleware.RegisterMapping('SettlementWorkbench', 'GET', getWorkbenchMapping);
    }
}

const SettlementWorkbenchMapping = {
    ConfigureMiddleware
};

export default SettlementWorkbenchMapping;
