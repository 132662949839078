import { Middleware } from '@driscollsinc/one-ring';
const getItemShippingRatesMapping = [
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('Currency', 'Currency'),
    Middleware.CreateMapping('PackagingUom', 'PackagingUom'),
    Middleware.CreateMapping('PackagingRate', 'PackagingRate'),
    Middleware.CreateMapping('FreightUom', 'FreightUom'),
    Middleware.CreateMapping('FreightRate', 'FreightRate'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ItemShippingRates', 'GET')) {
        Middleware.RegisterMapping('ItemShippingRates', 'GET', getItemShippingRatesMapping);
    }
}

const ItemShippingRatesMapping = {
    ConfigureMiddleware
};

export default ItemShippingRatesMapping;
