import ItemUomConversion from './ItemUomConversion';
import SettlementRate from './SettlementRate';
import RepackagingSkuMapping from './RepackagingSkuMapping';
import PoolWeek from './PoolWeek';
import ItemShippingRates from './ItemShippingRates';
import GrowerList from './GrowerCategory';
import CustomerCodeCategory from './CustomerCodeCategory';
import ReceiptLines from './ReceiptLines';
import PoolTable from './PoolTable';
import SalesInvoiceLines from './SalesInvoiceLines';
import InventoryIntransit from './InventoryIntransit';
import InventoryOnHand from './InventoryOnHand';
import VatRateMaintenance from './VatRateMaintenance';
import ConversionRate from './ConversionRate';
import AveragePriceReport from './AveragePriceReport';
import SettlementRecord from './SettlementRecord';
import LocationLookUp from './Locations';
import CommodityLookup from './CommodityLookup';
import CustomerCodeCategoryLookUp from './CustomerCodeCategoryLookUp';
import UOMLookUp from './UOMLookUp';
import GrowerCategoryLookUp from './GrowerCategoryLookUp';
import ApplicationLookUp from './ApplicationLookUp';
import EstimateGroupLookUp from './EstimateGroupLookUp';
import CoolerNameLookUp from './CoolerNameLookUp';
import FamilyEntityLookUp from './FamilyEntityLookUp';
import CommodityOffsetWeekLookUp from './CommodityOffsetWeekLookUp';
import WeeklySettlementStatus from './WeeklySettlementStatus';
import PendingApprovalRecords from './PendingApprovalRecords';
import ServiceDeductions from './ServiceDeductions';
import RepackageMovement from './RepackageMovement';
import GrowerFixedPrice from './GrowerFixedPrice';
import ShipmentRateSetup from './ShipmentDetails';
import DomesticSummary from './DomesticSummary';
import AmountAdjustmentSetup from './AmountAdjustment';
import costCenterSetup from './ReceiptCostCenter';
import GrowerSettlementReport from './GrowerSettlementReport';
import GrowerPercentageSetup from './GrowerPercentage';
import ReceiptByGrower from './ReceiptByGrower';
import JeShanghai from './JeShanghai';
import CCAllocation from './CcAllocation';
import Yunnan from './Yunnan';
import Qujing from './Qujing';
import Xishuangbanna from './Xishuangbanna';
import Sevenknot from './Sevenknot';
import ImportSettlementRateSetup from './ImportSettlementRate';
import ImportReceiptByCostCenter from './ImportReceiptByCostCenter';
import SummaryReportsCR from './SummaryReportsCR';
import GrowerModule from './GrowerModule';
import JournalAccount from './JournalAccount';
import JeShReport from './JeShReport';

function configureAllSetupCall() {
    var protocol = window.location.protocol + '//';
    var subdomain = window.location.origin.substring(protocol.length).toLowerCase();
    // subdomain.includes('localhost') && Setup({ options: { isVerbose: true } }); //For Console in localhost
    ItemUomConversion.configureSetupCall();
    SettlementRate.configureSetupCall();
    RepackagingSkuMapping.configureSetupCall();
    PoolWeek.configureSetupCall();
    ItemShippingRates.configureSetupCall();
    GrowerList.configureSetupCall();
    CustomerCodeCategory.configureSetupCall();
    ReceiptLines.configureSetupCall();
    PoolTable.configureSetupCall();
    SalesInvoiceLines.configureSetupCall();
    SalesInvoiceLines.configureSetupCall();
    InventoryIntransit.configureSetupCall();
    InventoryOnHand.configureSetupCall();
    VatRateMaintenance.configureSetupCall();
    ConversionRate.configureSetupCall();
    AveragePriceReport.configureSetupCall();
    SettlementRecord.configureSetupCall();
    LocationLookUp.configureSetupCall();
    CommodityLookup.configureSetupCall();
    CustomerCodeCategoryLookUp.configureSetupCall();
    UOMLookUp.configureSetupCall();
    GrowerCategoryLookUp.configureSetupCall();
    ApplicationLookUp.configureSetupCall();
    EstimateGroupLookUp.configureSetupCall();
    CoolerNameLookUp.configureSetupCall();
    FamilyEntityLookUp.configureSetupCall();
    GrowerModule.configureSetupCall();
    CommodityOffsetWeekLookUp.configureSetupCall();
    WeeklySettlementStatus.configureSetupCall();
    PendingApprovalRecords.configureSetupCall();
    ServiceDeductions.configureSetupCall();
    RepackageMovement.configureSetupCall();
    GrowerFixedPrice.configureSetupCall();
    ShipmentRateSetup.configureSetupCall();
    DomesticSummary.configureSetupCall();
    AmountAdjustmentSetup.configureSetupCall();
    costCenterSetup.configureSetupCall();
    GrowerSettlementReport.configureSetupCall();
    GrowerPercentageSetup.configureSetupCall();
    ReceiptByGrower.configureSetupCall();
    JeShanghai.configureSetupCall();
    CCAllocation.configureSetupCall();
    Yunnan.configureSetupCall();
    Qujing.configureSetupCall();
    Xishuangbanna.configureSetupCall();
    Sevenknot.configureSetupCall();
    ImportSettlementRateSetup.configureSetupCall();
    ImportReceiptByCostCenter.configureSetupCall();
    SummaryReportsCR.configureSetupCall();
    JournalAccount.configureSetupCall();
    JeShReport.configureSetupCall();
}

const SetupCall = {
    configureAllSetupCall
};

export default SetupCall;
