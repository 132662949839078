import { Middleware } from '@driscollsinc/one-ring';
const getRepackagingSkuMappingMapping = [
    Middleware.CreateMapping('RepackingItemId', 'RepackingItemId'),
    Middleware.CreateMapping('RepackingDesc', 'RepackingDesc'),
    Middleware.CreateMapping('OriginalItemId', 'OriginalItemId'),
    Middleware.CreateMapping('OriginalDesc', 'OriginalDesc'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('RepackagingSkuMapping', 'GET')) {
        Middleware.RegisterMapping('RepackagingSkuMapping', 'GET', getRepackagingSkuMappingMapping);
    }
}

const RepackagingSkuMappingMapping = {
    ConfigureMiddleware
};

export default RepackagingSkuMappingMapping;
