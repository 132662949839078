import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { LOOKUP_FIELDS } from '../constants';

function configureSetupCall() {
    SetupCall(
        LOOKUP_FIELDS.LocationLookUp,
        [
            {
                name: 'LookupId',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'LookupCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            }
        ],
        {}, //options
        (data) => {
            console.log('LocationLookUp REDUCER-- ', data);
        }
    );
}

const LocationLookUp = {
    configureSetupCall
};

export default LocationLookUp;
