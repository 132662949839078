import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'GrowerPercentage',
        [
            {
                name: 'GrowerSettlementRateID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'EffectiveStartDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'EffectiveEndDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'GrowerOwnPercentage',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FamilyEntityPercentage',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Grower Percentage REDUCER-- ', data);
        }
    );
}

const GrowerPercentage = {
    configureSetupCall
};

export default GrowerPercentage;
