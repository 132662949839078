export const SET_WEEKLY_SETTLEMENT_STATUS_DATA = 'SET_WEEKLY_SETTLEMENT_STATUS_DATA';
export const setWeeklySettlementStatusData = (data) => ({
    type: SET_WEEKLY_SETTLEMENT_STATUS_DATA,
    payload: data
});

export const CLEAR_WEEKLY_SETTLEMENT_STATUS_DATA = 'CLEAR_WEEKLY_SETTLEMENT_STATUS_DATA';
export const clearWeeklySettlementStatusData = () => ({
    type: CLEAR_WEEKLY_SETTLEMENT_STATUS_DATA,
    payload: null
});

export const SET_SELECTED_POOL_WEEk = 'SET_SELECTED_POOL_WEEk';
export const setSelectedPoolWeek = (poolWeek) => ({
    type: SET_SELECTED_POOL_WEEk,
    payload: poolWeek
});

export const SET_SELECTED_SETTLEMENT_RECORD = 'SET_SELECTED_SETTLEMENT_RECORD';
export const setSelectedSettlementRecord = (data) => ({
    type: SET_SELECTED_SETTLEMENT_RECORD,
    payload: data
});

export const SET_POOLWEEK_FREEZE_DATA = 'SET_POOLWEEK_FREEZE_DATA';
export const setPoolweekFreezeData = (data) => ({
    type: SET_POOLWEEK_FREEZE_DATA,
    payload: data
});