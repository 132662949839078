import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'ServiceDeductions',
        [
            {
                name: 'Account',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Amount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'BerryType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Date',
                type: FIELD_UNITS.DATE_TIME
            },
            {
                name: 'LastModifiedDate',
                type: FIELD_UNITS.DATE_TIME
            },
            {
                name: 'Memo',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'RefItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TransactionKey',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TransactionNumber',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Type',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Vendor',
                type: FIELD_UNITS.RAW
            }
        ],
        {}, //options
        (data) => {
            console.log('Service Deduction REDUCER-- ', data);
        }
    );
}

const ServiceDeduction = {
    configureSetupCall
};

export default ServiceDeduction;
