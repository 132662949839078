import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'GrowerFixedPrice',
        [
            {
                name: 'GrowerFixedPriceRateID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'VendorCode',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Currency',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Price',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'EffectiveStartDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'EffectiveEndDate',
                type: FIELD_UNITS.DATE
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Grower Fixed Price REDUCER-- ', data);
        }
    );
}

const GrowerFixedPrice = {
    configureSetupCall
};

export default GrowerFixedPrice;
