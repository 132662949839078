import { SetupCall } from '@driscollsinc/driscolls-display-rules';
import { LOOKUP_FIELDS } from '../constants';
import { lookupSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        LOOKUP_FIELDS.GrowerModule,
        [...lookupSetupFields],
        {}, //options
        (data) => {
            console.log('GrowerModule REDUCER-- ', data);
        }
    );
}

const GrowerModule = {
    configureSetupCall
};

export default GrowerModule;
