export const SET_FILTERS = 'SET_FILTERS';
export const setFilters = (name, option) => ({
    type: SET_FILTERS,
    payload: {
        name,
        option
    }
});

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const clearFilters = () => ({
    type: CLEAR_FILTERS,
    payload: {}
});

export const CLEAR_ONE_FILTER = 'CLEAR_ONE_FILTER';
export const clearOneFilter = (filter) => ({
    type: CLEAR_ONE_FILTER,
    payload: { filter }
});

export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const setAllFilters = (filters) => ({
    type: SET_ALL_FILTERS,
    payload: { filters }
});

export const SET_DATA_SETUP_FILTER = 'SET_DATA_SETUP_FILTER';
export const setDataSetupFilters = (filters) => ({
    type: SET_DATA_SETUP_FILTER,
    payload: filters
});
