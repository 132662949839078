import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'ImportSettlementRate',
        [
            {
                name: 'ImportSettlementRateID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Module',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Variety',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Breeder',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CCPercent',
                type: FIELD_UNITS.NUMBER
                // options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DSAServiceFee',
                type: FIELD_UNITS.NUMBER
                //  options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Import Settlement Rate REDUCER-- ', data);
        }
    );
}

const ImportSettlementRate = {
    configureSetupCall
};

export default ImportSettlementRate;
