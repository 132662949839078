import { FIELD_UNITS } from '@driscollsinc/driscolls-display-rules';

export const dateTimeSetupFields = [
    {
        name: 'CreatedBy',
        type: FIELD_UNITS.STRING
    },
    {
        name: 'ModifiedBy',
        type: FIELD_UNITS.STRING
    },
    {
        name: 'CreatedDateTime',
        type: FIELD_UNITS.DATE_TIME
    },
    {
        name: 'ModifiedDateTime',
        type: FIELD_UNITS.DATE_TIME
    }
];

export const lookupSetupFields = [
    {
        name: 'LookupId',
        type: FIELD_UNITS.NUMBER,
        options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    },
    {
        name: 'LookupCode',
        type: FIELD_UNITS.STRING
    },
    {
        name: 'Description',
        type: FIELD_UNITS.STRING
    },
    {
        name: 'Meaning',
        type: FIELD_UNITS.STRING
    },
    {
        name: 'BusinessUnit',
        type: FIELD_UNITS.STRING
    }
];
