import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'SettlementRate',
        [
            {
                name: 'SettlementRateID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LocationCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CoolerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Application',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ApplicationInNetSuite',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CommissionRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'ShareGrGrowerPercent',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ShareDriscollsFarming',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ShareGrower',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'MidsizeDriscollsFarming',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'MidsizeGrower',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'MidSizeGrGrowerPercent',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'MidSizeGrDriscollsPercent',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ApplicationFullName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ShareGrDriscollsPercent',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'EndDateActive',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'StartDateActive',
                type: FIELD_UNITS.DATE
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Settlement Rate REDUCER-- ', data);
        }
    );
}

const SettlementRate = {
    configureSetupCall
};

export default SettlementRate;
