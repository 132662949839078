import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { LOOKUP_FIELDS } from '../constants';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        LOOKUP_FIELDS.CommodityLookup,
        [
            {
                name: 'LookupId',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LookupCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Description',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Meaning',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'EndDateActive',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'StartDateActive',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'isDeleted',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Attribute1',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Attribute2',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Attribute3',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Attribute4',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Attribute5',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Meaning',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LookupType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'DisplaySequence',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'EnabledFlag',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Tag',
                type: FIELD_UNITS.RAW
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('CommodityLookup REDUCER-- ', data);
        }
    );
}

const CommodityLookup = {
    configureSetupCall
};

export default CommodityLookup;
