import { SET_PRICING_POOL_DATA } from '../actions/PricingPoolAction';

const initialState = {
    data: [],
    parentPools: {},
    pricingPoolsMap: {},
    settlementPoolsMap: {}
};

const PricingPoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRICING_POOL_DATA: {
            return Object.assign({}, state, {
                data: action.payload.data,
                parentPools: action.payload.parentPools,
                pricingPoolsMap: action.payload.pricingPoolsMap,
                settlementPoolsMap: action.payload.settlementPoolsMap
            });
        }

        default:
            return state;
    }
};

export default PricingPoolReducer;
