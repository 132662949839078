import { LOOK_UP_FILTER } from '../actions/LookupAction';

const initialState = {
    records: []
};

const lookupReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOOK_UP_FILTER: {
            let records = [];
            records = action.payload;

            return Object.assign({}, state, {
                records
            });
        }

        default:
            return state;
    }
};

export default lookupReducer;
