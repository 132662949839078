import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'ImportReceiptByCostCenter',
        [
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ItemID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerLocation',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'SubGrower',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Abbreviation',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Module',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Variety',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Location',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Breeder',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TrayToKilogram',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'TotalTraysReceived',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'CommissionRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            ...dateTimeSetupFields
        ],
        {}
    );
}

const ImportReceiptByCostCenter = {
    configureSetupCall
};

export default ImportReceiptByCostCenter;
