import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'GrowerList',
        [
            {
                name: 'GrowerListID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LocationCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ContactEmailId',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FamilyEntity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Geography',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CustomerCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'VendorCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Lang',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Grower Category REDUCER-- ', data);
        }
    );
}

const GrowerCategory = {
    configureSetupCall
};

export default GrowerCategory;
