import { Middleware } from '@driscollsinc/one-ring';
const getWorkbenchMapping = [
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('CUSTOMERCODE', 'CUSTOMERCODE'),
    Middleware.CreateMapping('CATEGORY', 'CATEGORY'),
    Middleware.CreateMapping('EXCLUDEAVERAGEPRICING', 'EXCLUDEAVERAGEPRICING')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('CustomerCategory', 'GET')) {
        Middleware.RegisterMapping('CustomerCategory', 'GET', getWorkbenchMapping);
    }
}

const CustomerCategoryMapping = {
    ConfigureMiddleware
};

export default CustomerCategoryMapping;
