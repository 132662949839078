import { SET_POOLWEEK_DATA, CLEAR_POOLWEEK_DATA } from '../actions/PoolWeekDefAction';

const initialState = {
    records: [],
    totalRecords: 0
};

const PoolWeekReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POOLWEEK_DATA:{
            let records = [];
            records = action.payload.Results;

            return Object.assign({}, state, {
                records,
                totalRecords: action.payload.totalCount
            });
        }
        case CLEAR_POOLWEEK_DATA:
            return Object.assign({}, state, {
                records: [],
                totalRecords: 0
            });

        default:
            return state;
    }
};

export default PoolWeekReducer;
