import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'GoodsInTransit',
        [
            {
                name: 'ItemID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Quantity',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },

            {
                name: 'ItemDescription',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'InventoryStatus',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Memo',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ShipFromLocation',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ShipToLocation',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TransactionKey',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'DateReceived',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'DateShipped',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'LastModifiedDate',
                type: FIELD_UNITS.DATE
            }
        ],
        {}, //options
        (data) => {
            console.log('Sales Invoice Lines REDUCER-- ', data);
        }
    );
}

const SalesInvoiceLines = {
    configureSetupCall
};

export default SalesInvoiceLines;
