import { languagesSupported } from './translations.js';
// [English, Spanish, Chinese, French]

const APP_TRANSLATIONS = {
    berries: {
        black: ['Blackberry', 'Mora', '黑莓', 'la mûre'],
        blacks: ['Blackberries', 'Moras', '黑莓', 'Mûres'],
        blue: ['Blueberry', 'Arándano', '蓝莓', 'Myrtille'],
        blues: ['Blueberries', 'Arándanos', '蓝莓', 'Myrtilles'],
        rasp: ['Raspberry', 'Framboise', '覆盆子', 'Framboise'],
        rasps: ['Raspberries', 'Frambuesas', '山莓', 'Framboises'],
        straw: ['Strawberry', 'Fresa', '草莓', 'Fraise'],
        straws: ['Strawberries', 'Fresas', '草莓', 'Des fraises']
    },
    buttonTempSubmit: ['Submit', 'Enviar', '提交', 'Soumettre'],

    /* Added logic to support multi languages in separated file ad then merging in above format */
    ...Object.keys(languagesSupported.enUS).reduce((acc, key) => {
        acc[key] = Object.keys(languagesSupported).map((lang) => languagesSupported[lang][key] || languagesSupported['enUS'][key]);
        return acc;
    }, {})
};
export default APP_TRANSLATIONS;
