import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'ReceiptCostCenter',
        [
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TotalTraysReceived',
                type: FIELD_UNITS.NUMBER
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FamilyEntity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CommissionRate',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Application',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Location',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CostCenter',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerID',
                type: FIELD_UNITS.RAW
            }
        ],
        {}, //options
        (data) => {
            console.log('cost Center REDUCER-- ', data);
        }
    );
}

const costCenter = {
    configureSetupCall
};

export default costCenter;
