import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'CCAllocation',
        [
            {
                name: 'GrowerID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'JECode',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'JECostCenter',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'QuantityKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrowerAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DriscollsAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Total',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrowerOwn',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            }
        ],
        {}, //options
        (data) => {
            console.log('CCAllocation REDUCER-- ', data);
        }
    );
}

const CCAllocation = {
    configureSetupCall
};

export default CCAllocation;
