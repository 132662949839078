import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';
// this setup call will be used in user story GGS-24
function configureSetupCall() {
    SetupCall(
        'AveragePriceReport',
        [
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ItemInvSummaryID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'EstimateGroup',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ReportType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'OpeningQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ReceiptQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Balance',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NormalSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NormalSalesAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DSharedSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DSharedSalesAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NormalSalesUnitPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DisposalQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'RepackMovementSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NormalSalesIncludeDshared',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ServiceDeductionAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AbnormalSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AbnormalSalesAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AbnormalSalesUnitPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SampleDamageSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'QualityIssueSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'RepackagingSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'OnHand',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'InTransit',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ClosingQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SettlementUnitPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SettlementUnitPriceWithRepack',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PrelimAverageSettlementPriceWK1',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PrelimAverageSettlementPriceWK2',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PrelimAverageSettlementPriceWK3',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FinalAverageSettlementPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 4, maximumFractionDigits: 4 }
            },
            {
                name: 'FinalTrueUpAverageSettlementPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PrelimWODamageAverageSettlementPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ReceiptQtySoldFlag',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TurnOverRateWK1',
                type: FIELD_UNITS.PERCENTAGE,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'TurnOverRateWK2',
                type: FIELD_UNITS.PERCENTAGE,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'TurnOverRateWK3',
                type: FIELD_UNITS.PERCENTAGE,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'SoldQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SoldQtyWK1',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SoldQtyWK2',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SoldQtyWK3',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VarianceNormalSettlementUnitPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'VarianceNormalSettlementUnitPriceWithRepack',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Average Price Report REDUCER-- ', data);
        }
    );
}

const AveragePriceReport = {
    configureSetupCall
};

export default AveragePriceReport;
