export const SET_POOLWEEK_DATA = 'SET_POOLWEEK_DATA';
export const setPoolWeekData = (data) => ({
    type: SET_POOLWEEK_DATA,
    payload: data
});

export const CLEAR_POOLWEEK_DATA = 'CLEAR_POOLWEEK_DATA';
export const clearPoolWeekData = () => ({
    type: CLEAR_POOLWEEK_DATA,
    payload: null
});
