import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'poolweek',
        [
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CreatedBy',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CreatedDateTime',
                type: FIELD_UNITS.DATE_TIME
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PoolWeekEndDate',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PoolWeekStartDate',
                type: FIELD_UNITS.RAW
            }
        ],
        {}, //options
        (data) => {
            console.log('PoolWeek REDUCER-- ', data);
        }
    );
}

const PoolWeek = {
    configureSetupCall
};

export default PoolWeek;
