import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import masterReducer from './MasterReducer';
import weeklySettlementStatusReducer from './WeeklySettlementStatusReducer';
import settlementWorkbenchReducer from './settlementWorkbenchReducer';
import PoolWeekReducer from './PoolWeekReducer';
import CustomerCategoryReducer from './CustomerCategoryReducer';
import filterReducer from './FilterReducer';
import pricingPoolReducer from './PricingPoolReducer';
import SettlementWorkBenchDetailsReducer from './SettlementWorkbenchDetailsReducer';
import lookupReducer from './LookupReducer';
import vendorCodeReducer from './VendorCodeReducer';

import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_COMMON_DIALOG,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    SHOW_TOAST,
    SERVICE_WORKER_UPDATED,
    SERVICE_WORKER_UPDATE,
    OPEN_DRAWER,
    SET_USER_IS_ADMIN,
    SET_USER_ROLES,
    SHOW_REPORTBACKLINK
} from '../actions/actions';

const initialState = {
    showLoadingScreen: false,
    pageTitle: '',
    userIsAdmin: false,
    commonDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp'
    },
    drawerOpen: false,
    toastMessage: '',
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    userRoles: [],
    selectedRole: ''
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: (action.payload || '').length > 0 ? 'GGS: ' + action.payload : 'GGS'
            });

        case SET_USER_IS_ADMIN:
            return {
                ...state,
                userIsAdmin: action.payload.isAdmin,
                selectedRole: action.payload.group
            };
        case SET_USER_ROLES:
            return {
                ...state,
                userRoles: action.payload
            };
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case SHOW_REPORTBACKLINK:
            return {
                ...state,
                showReportBackLink: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_COMMON_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    isMessage: !!action.payload.isMessage
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                isSuccess: action.payload.isSuccess
            };
        case OPEN_DRAWER:
            return {
                ...state,
                drawerOpen: action.payload
            };
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };

        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    localize: localizeReducer,
    masterReducer,
    weeklySettlementStatusReducer,
    settlementWorkbenchReducer,
    PoolWeekReducer,
    CustomerCategoryReducer,
    filterReducer,
    pricingPoolReducer,
    lookupReducer,
    vendorCodeReducer,
    SettlementWorkBenchDetailsReducer
});
