import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'ConversionRate',
        [
            {
                name: 'Id',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ExchangeRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 4, maximumFractionDigits: 4 }
            },
            {
                name: 'DriscollRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ExchangeRateText',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FromSymbol',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ToSymbol',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PullDate',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'RateDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'RequestedBy',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'RateDelay',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PullType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LastResult',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LastResultMessage',
                type: FIELD_UNITS.STRING
            }
        ],
        {}, //options
        (data) => {
            console.log('Conversion Rate REDUCER-- ', data);
        }
    );
}

const ConversionRate = {
    configureSetupCall
};

export default ConversionRate;
