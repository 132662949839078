export const BUSINESS_UNIT_COLUMN_MAP = {
    DOC: {
        receiptLinesColumns: {
            ReceiptID: 'Receipt Id',
            LineNum: 'Line',
            TransDate: 'Date',
            VendorNameChinese: 'Vendor Name Chinese',
            VendorName: 'Vendor Name',
            ItemID: 'Item Number',
            Commodity: 'Commodity',
            Quantity: 'Quantity',
            CostCenter: 'Cost Center',
            Variety: 'Variety',
            Application: 'Application',
            QtyOrdered: 'Qty Ordered',
            Memo: 'Memo',
            Location: 'Location',
            QCScore: 'QC Score',
            PoolWeek: 'Pool Week',
            ModifiedDateTime: 'Modified Date Time'
        },
        salesInvoiceLineColumns: {
            InvoiceID: 'Invoice ID',
            LineNum: 'Line Num',
            InvoiceDate: 'Invoice Date',
            ItemID: 'Item ID',
            ItemDescription: 'Item Description',
            PickupLocation: 'Pickup Location',
            InventTransID: 'Invent Trans ID',
            Quantity: 'Quantity',
            InvoiceAmount: 'Invoice Amount',
            CustomerCode: 'Customer Code',
            CustomerName: 'Customer Name',
            CustomerGeography: 'Customer Geography',
            Category: 'Category',
            ItemRate: 'Item Rate',
            Memo: 'Memo',
            SalesID: 'Sales ID',
            PoolWeek: 'Pool Week',
            ModifiedDateTime: 'Modified Date Time'
            // VendorID: 'Vendor Account',
            // ReceiptID: 'Receipt ID',
            // ContractID: 'Contract ID',
            // CommissionPercent: 'Commission %',
            // FixedFeeCostAmount: 'Fixed Cost Amount',
            // BusinessUnit: 'Business Unit',
            // SalesInvoiceID: 'Sales Invoice ID',
        },
        inventoryOnHandColumns: {
            ItemID: 'Item',
            Description: 'Description',
            PreferredVendor: 'Preferred Vendor',
            OnHand: 'On Hand',
            PoolWeek: 'Pool Week',
            TransactionKey: 'Transaction Key',
            BusinessUnit: 'Business Unit',
            LastModifiedDate: 'Modified Date Time'
        },
        goodsInTransitColumns: {
            ItemID: 'Item ID',
            ItemDescription: 'Item Description',
            TransactionKey: 'Document Number',
            InventoryStatus: 'Status',
            ShipToLocation: 'Ship To',
            Memo: 'Quantity Memo',
            DateShipped: 'Date Shipped',
            DateReceived: 'Date Received',
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week',
            Quantity: 'Quantity',
            ShipFromLocation: 'Ship From Location',
            LastModifiedDate: 'Last Modified Date'
        },
        ServiceDeductionColumns: {
            RefItemID: 'Item Id',
            Account: 'Account',
            Amount: 'Amount',
            BerryType: 'Berry Type',
            Date: 'Date',
            Memo: 'Memo',
            PoolWeek: 'Pool Week',
            TransactionKey: 'Transaction Key',
            TransactionNumber: 'Transaction Number',
            Type: 'Type',
            Vendor: 'Vendor',
            BusinessUnit: 'Business Unit',
            LastModifiedDate: 'Last Modified Date'
        },
        settlementReportColumns: {
            ItemID: 'Item ID',
            GrowerID: 'Grower ID',
            Commodity: 'Commodity',
            PaymentDate: 'Payment Date',
            ReportType: 'Report Type',
            TotalKilosReceived: 'Total Kilos Received',
            FreightRate: 'Freight Rate',
            PackageDeduction: 'Package Deduction',
            GrossPoolAmount: 'Gross Pool Amount',
            CommissionNetPoolReturn: 'Commission Net Pool Return',
            FreightDeduction: 'Freight Deduction',
            CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price Per Kg',
            AveragePricePerKg: 'Average Price Per Kg',
            VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price Per Kg',
            PackageRate: 'Package Rate',
            UOMConversionRate: 'UOM Conversion Rate',
            // ApprovedDate: 'Approved Date',
            // SubmittedDate: 'Submitted Date',
            VATNetPoolReturn: 'VAT Net Pool Return',
            AveragePricePerTray: 'Average Price Per Tray',
            VATPercent: 'VAT Percent',
            NetFruitReturnPricePerKg: 'NetFruit Return Price Per Kg',
            TotalTraysReceived: 'Total Trays Received',
            Version: 'Version',
            VATShareGrower: 'VAT Share Grower',
            // BusinessUnit: 'Business Unit',
            CommissionRate: 'Commission Rate',
            SettlementCurrency: 'Settlement Currency',
            NetFruitBeforeDAPshare: 'Net Fruit Before DAP share',
            PoolWeek: 'Pool Week',
            DAPShare: 'DAP Share',
            SubmittedBy: 'Submitted By',
            ApprovedBy: 'Approved By',
            CreatedBy: 'Created By',
            CreatedDateTime: 'Created Date Time',
            ModifiedBy: 'Modified By',
            ModifiedDateTime: 'Modified Date Time'
        },
        growerSettlementReport: {
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week',
            Commodity: 'Commodity',
            Abbreviation: 'SPEC',
            ItemID: 'Item ID',
            CommissionRate: 'Commission Rate',
            TrayToKilogram: 'Tray To Kilogram',
            PackageRate: 'Package Rate',
            Category: 'Category',
            FamilyEntity: 'Family Entity',
            GrowerID: 'Grower ID',
            GrowerName: 'Grower Name',
            TotalTraysReceived: 'Total Trays Received',
            TotalKilosReceived: 'Total Kilos Received',
            ReportType: 'Report Type',
            NetFruitReturnPricePerKg: 'Net Fruit Return Price/Kilo (Avg)',
            NetReturnToGrower: 'Net Return To Grower',
            PackagingCredit: 'Packaging Credit',
            PackagingIssurance: 'Packaging Issurance',
            PkgNetReturnToGrower: 'Pkg Net Return To Grower',
            ShareGrowerAllowance: 'Share Grower Allowance',
            ShareGrowerDeduction: 'Share Grower Deduction',
            AllDeductNetReturnToGrower: 'All Deduct Net Return To Grower',
            SharePercentage: 'Share Percentage',
            ShareYunnan: 'Share Yunnan',
            ShareXishuangbanna: 'Share Xishuangbanna',
            ShareQujing: 'Share Qujing',
            ShareSevenknot: 'Share Seven knot KY',
            DriscollsYunnanAP: 'Driscolls Yunnan AP',
            DriscollsXishuangbannaAP: 'Driscolls Xishuangbanna AP',
            DriscollsQujingAP: 'Driscolls Qujing AP',
            DriscollsSevenknotAP: 'Seven knot KY AP',
            TotalCashToGrower: 'Final Net Return to Grower'
        },
        avgPriceReportColumnsMapping: {
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            ReportType: 'Report Type',
            PrelimWODamageAverageSettlementPrice: 'Prelim WODamage Average Settlement Price',
            PrelimAverageSettlementPrice: 'Prelim Average Settlement Price',
            AbnormalSalesAmount: 'Abnormal Sales Amount',
            SettlementUnitPrice: 'Settlement Unit Price',
            RepackagingSalesQty: 'Repackaging Sales Qty',
            FinalTrueUpAverageSettlementPrice: 'Final True Up Average Settlement Price',
            SoldQty: 'Sold Qty',
            ReceiptQty: 'Receipt Qty',
            EstimateGroup: 'Estimate Group',
            FinalAverageSettlementPrice: 'Final Average Settlement Price',
            SampleDamageSalesQty: 'Sample Damage Sales Qty',
            ClosingQty: 'Closing Qty',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            OpeningQty: 'Opening Qty',
            OnHand: 'On Hand',
            TurnOverRateWK1: 'Turn Over Rate Wk1',
            TurnOverRateWK3: 'Turn Over Rate WK3',
            NormalSalesQty: 'Normal Sales Qty',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            // BusinessUnit: 'Business Unit',
            InTransit: 'In Transit',
            NormalSalesAmount: 'Normal Sales Amount',
            QualityIssueSalesQty: 'Quality Issue Sales Qty',
            AbnormalSalesUnitPrice: 'Abnormal Sales Unit Price',
            // PoolWeek: 'Pool Week',
            CreatedBy: 'Created By',
            CreatedDateTime: 'Created Date Time',
            ModifiedBy: 'Modified By',
            ModifiedDateTime: 'Modified Date Time'
        },
        weeklySalesReportColumns: {
            ItemID: 'Item ID',
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            PoolWeek: 'Pool Week',
            BusinessUnit: 'Business Unit',
            NormalSalesQty: 'Normal Sales Qty',
            NormalSalesAmount: 'Normal Sales Amount',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            DSharedSalesQty: 'D Shared Sales Qty',
            DSharedSalesAmount: 'D Shared Sales Amount',
            DSharedSalesUnitPrice: 'D Shared Sales Unit Unit Price',
            SampleDamageSalesQty: 'Sample Damage Sales Qty',
            QualityIssueSalesQty: 'Quality Issue Sales Qty',
            RepackagingSalesQty: 'Repackaging Sales Qty',
            ServiceDeductionAmount: 'Service Deduction Amount',
            SettlementUnitPrice: 'Settlement Unit Price',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            VarianceNormalSettlementUnitPrice: 'Variance Normal Settlement Unit Price',
            VarianceNormalSettlementUnitPriceWithRepack: 'Variance Normal Settlement Unit PriceWith Repack',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            AbnormalSalesAmount: 'Abnormal Sales Amount',
            AbnormalSalesUnitPrice: 'Abnormal Sales Unit Price',
            DisposalTotal: 'Disposal Total' // new
        },
        inventoryMovementReportColumns: {
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            LastWeekOnHand: 'Last Week On Hand',
            LastWeekInTransit: 'Last Week In Transit',
            LastWeekRepackSKUTransferBack: 'Last Week Repack SKU Transfer Back',
            // OpeningQty: 'Opening Qty',
            ReceiptQty: 'Receipt Qty',
            NormalSalesIncludeDshared: 'Normal Sales Qty', // renamed
            AbnormalSalesQty: 'Abnormal Sales Qty',
            DisposalQty: 'Disposal Qty',
            RepackMovementSalesQty: 'Repackaging Sales Qty', // renamed
            ClosingQty: 'Closing Qty',
            RepackSKUTransferBack: 'Repack SKU Transfer Back',
            OnHand: 'On Hand',
            InTransit: 'In Transit'
        },
        averageSalesPriceReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            FinalAverageSettlementPrice: 'Final Average Settlement Price',
            FinalTrueUpAverageSettlementPrice: 'Final True Up Average Settlement Price',
            PrelimWODamageAverageSettlementPrice: 'Prelim WO Damage Average Settlement Price'
        },
        turnoverRateReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            Spec: 'Spec',
            TurnOverRateWK1: 'Turn Over Rate WK1',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            TurnOverRateWK3: 'Turn Over Rate WK3'
        },
        inventoryOutboundReportColumns: {
            PoolWeek: 'Pool Week',
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            OpeningQty: 'Opening Qty',
            ReceiptQty: 'ReceiptQty',
            SoldQty: 'Sold Qty',
            NormalSalesQty: 'Normal Sales Qty',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            DisposalQty: 'Disposal Qty',
            ClosingQty: 'Closing Qty',
            SoldQtyWK1: 'Sold Qty WK1',
            TurnOverRateWK1: 'Turn Over Rate WK1',
            PrelimAverageSettlementPriceWK1: 'Prelim Average Settlement Price WK1',
            SoldQtyWK2: 'Sold Qty WK2',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            PrelimAverageSettlementPriceWK2: 'Prelim Average Settlement Price WK2',
            SoldQtyWK3: 'Sold Qty WK3',
            TurnOverRateWK3: 'Turn Over Rate WK3',
            PrelimAverageSettlementPriceWK3: 'Prelim Average Settlement Price WK3',
            FinalAverageSettlementPrice: 'Final Average Settlement Price'
        },
        agedInventoryReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Balance: 'Balance',
            ReceiptQty: 'Receipt Qty',
            SoldQty: 'Sold Qty'
        },
        settlementWorkBenchSubmitterColumns: {
            Status: { name: 'Status', width: '15%' },
            PricingPoolID: { name: 'Pricing Pool', width: '15%' },
            PoolID: { name: 'Pool Id', width: '15%' },
            ExpectedReceiptQty: { name: 'Expected Qty', width: '15%' },
            Description: { name: 'Description', width: '30%' },
            Reports: { name: 'Reports', width: '10%' }
        },
        settlementWorkBenchApproverColumns: {
            // Status: 'Status',
            // PoolWeek: 'Pricing Week',
            // PoolID: 'Pool Id',
            // SubmittedBy: 'Submitted By',
            // SubmittedDate: 'Submitted Date'
            Status: { name: 'Status', width: '15%' },
            PricingPoolID: { name: 'Pricing Pool', width: '15%' },
            PoolID: { name: 'Pool Id', width: '15%' },
            ExpectedReceiptQty: { name: 'Expected Qty', width: '15%' },
            Description: { name: 'Description', width: '30%' },
            Reports: { name: 'Reports', width: '10%' }
        },
        summaryDomesticColumns: {
            PaymentDate: 'Payment Date',
            PoolWeek: 'Pool Week',
            SettlementCurrency: 'Currency',
            Commodity: 'Commodity',
            Abbreviation: 'SPEC',
            ItemID: 'Item ID',
            CommissionRate: 'Commission Rate',
            TotalTraysReceived: 'Total Trays Received',
            TotalKilosReceived: 'Total Kilos Received',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            AveragePricePerTray: 'Average Price Per Tray',
            AveragePricePerKg: 'Average Price Per Kg',
            // GrossPoolAmount: 'Gross Pool Amount',
            PackageDeduction: 'Package Deduction',
            FreightDeduction: 'Freight Deduction',
            PkgNetFruitReturnPricePerKg: 'Pkg Net Fruit Return Price Per KG',
            NetFruitBeforeDAPshare: 'Net Fruit Before DAP share',
            // PkgNetFruitReturnPricePerKg: 'Net Fruit Return Price Per Kg',
            DAPShare: 'DMSH Share',
            CommissionNetPoolReturn: 'Commission Net Pool Return',
            CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price Per Kg',
            VATPercent: 'VAT Percent',
            VATShareGrower: 'VAT Share Grower',
            VATNetPoolReturn: 'VAT Net Pool Return',
            VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price Per Kg',
            ReportType: 'Report Type',
            EstimateGroup: 'Estimate Group',
            TrayToKilogram: 'Tray To Kilogram',
            // UOMConversionRate: 'UOM Conversion Rate',
            PackageRate: 'Package Rate',
            FreightRate: 'Freight Rate',
            SubmittedBy: 'Submitted By',
            SubmittedDate: 'Submitted Date',
            ApprovedBy: 'Approved By',
            ApprovedDate: 'Approved Date'
        },
        costCenterColumns: {
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            GrowerID: 'Grower ID',
            GrowerName: 'Grower Name',
            CostCenter: 'Cost Center',
            Application: 'Application',
            Location: 'Location',
            TotalTraysReceived: 'Total Trays Received',
            CommissionRate: 'Commission Rate',
            Category: 'Grower Category', // renamed
            FamilyEntity: 'Family Entity',
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week'
        }
    },
    DOTA: {
        receiptLinesColumns: {
            // VendorID: 'VENDOR ACCOUNT',
            ReceiptID: 'Receipt Id',
            LineNum: 'Line',
            TransDate: 'Date',
            VendorNameChinese: 'Vendor Name Chinese',
            VendorName: 'Vendor Name',
            ItemID: 'Item Number',
            Commodity: 'Commodity',
            Quantity: 'Quantity',
            CostCenter: 'Cost Center',
            Variety: 'Variety',
            Application: 'Application',
            QtyOrdered: 'Qty Ordered',
            Memo: 'Memo',
            Location: 'Location',
            QCScore: 'QC Score',
            PoolWeek: 'Pool Week',
            ModifiedDateTime: 'Modified Date Time'
        },
        salesInvoiceLineColumns: {
            InvoiceID: 'Invoice ID',
            LineNum: 'Line Num',
            InvoiceDate: 'Invoice Date',
            ItemID: 'Item ID',
            ItemDescription: 'Item Description',
            PickupLocation: 'Pickup Location',
            InventTransID: 'Invent Trans ID',
            Quantity: 'Quantity',
            InvoiceAmount: 'Invoice Amount',
            CustomerCode: 'Customer Code',
            CustomerName: 'Customer Name',
            CustomerGeography: 'Customer Geography',
            Category: 'Category',
            ItemRate: 'Item Rate',
            Memo: 'Memo',
            SalesID: 'Sales ID',
            PoolWeek: 'Pool Week',
            ModifiedDateTime: 'Modified Date Time'
            // VendorID: 'Vendor Account',
            // ReceiptID: 'Receipt ID',
            // ContractID: 'Contract ID',
            // CommissionPercent: 'Commission %',
            // FixedFeeCostAmount: 'Fixed Cost Amount',
            // BusinessUnit: 'Business Unit',
            // SalesInvoiceID: 'Sales Invoice ID',
        },
        inventoryOnHandColumns: {
            ItemID: 'Item',
            Description: 'Description',
            PreferredVendor: 'Preferred Vendor',
            OnHand: 'On Hand',
            PoolWeek: 'Pool Week',
            TransactionKey: 'Transaction Key',
            BusinessUnit: 'Business Unit',
            LastModifiedDate: 'Modified Date Time'
        },
        goodsInTransitColumns: {
            ItemID: 'Item ID',
            ItemDescription: 'Item Description',
            TransactionKey: 'Document Number',
            InventoryStatus: 'Status',
            ShipToLocation: 'Ship To',
            Memo: 'Quantity Memo',
            DateShipped: 'Date Shipped',
            DateReceived: 'Date Received',
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week',
            Quantity: 'Quantity',
            ShipFromLocation: 'Ship From Location',
            LastModifiedDate: 'Last Modified Date'
        },
        ServiceDeductionColumns: {
            RefItemID: 'Item Id',
            Account: 'Account',
            Amount: 'Amount',
            BerryType: 'Berry Type',
            Date: 'Date',
            Memo: 'Memo',
            PoolWeek: 'Pool Week',
            TransactionKey: 'Transaction Key',
            TransactionNumber: 'Transaction Number',
            Type: 'Type',
            Vendor: 'Vendor',
            BusinessUnit: 'Business Unit',
            LastModifiedDate: 'Last Modified Date'
        },
        settlementReportColumns: {
            ItemID: 'Item ID',
            GrowerID: 'Grower ID',
            Commodity: 'Commodity',
            PaymentDate: 'Payment Date',
            ReportType: 'Report Type',
            TotalKilosReceived: 'Total Kilos Received',
            FreightRate: 'Freight Rate',
            PackageDeduction: 'Package Deduction',
            GrossPoolAmount: 'Gross Pool Amount',
            CommissionNetPoolReturn: 'Commission Net Pool Return',
            FreightDeduction: 'Freight Deduction',
            CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price Per Kg',
            AveragePricePerKg: 'Average Price Per Kg',
            VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price Per Kg',
            PackageRate: 'Package Rate',
            UOMConversionRate: 'UOM Conversion Rate',
            // ApprovedDate: 'Approved Date',
            // SubmittedDate: 'Submitted Date',
            VATNetPoolReturn: 'VAT Net Pool Return',
            AveragePricePerTray: 'Average Price Per Tray',
            VATPercent: 'VAT Percent',
            NetFruitReturnPricePerKg: 'NetFruit Return Price Per Kg',
            TotalTraysReceived: 'Total Trays Received',
            Version: 'Version',
            VATShareGrower: 'VAT Share Grower',
            // BusinessUnit: 'Business Unit',
            CommissionRate: 'Commission Rate',
            SettlementCurrency: 'Settlement Currency',
            NetFruitBeforeDAPshare: 'Net Fruit Before DAP share',
            PoolWeek: 'Pool Week',
            DAPShare: 'DAP Share',
            SubmittedBy: 'Submitted By',
            ApprovedBy: 'Approved By',
            CreatedBy: 'Created By',
            CreatedDateTime: 'Created Date Time',
            ModifiedBy: 'Modified By',
            ModifiedDateTime: 'Modified Date Time'
        },
        growerSettlementReport: {
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week',
            Commodity: 'Commodity',
            Abbreviation: 'SPEC',
            ItemID: 'Item ID',
            CommissionRate: 'Commission Rate',
            TrayToKilogram: 'Tray To Kilogram',
            PackageRate: 'Package Rate',
            Category: 'Category',
            FamilyEntity: 'Family Entity',
            GrowerID: 'Grower ID',
            GrowerName: 'Grower Name',
            TotalTraysReceived: 'Total Trays Received',
            TotalKilosReceived: 'Total Kilos Received',
            ReportType: 'Report Type',
            NetFruitReturnPricePerKg: 'Net Fruit Return Price/Kilo (Avg)',
            NetReturnToGrower: 'Net Return To Grower',
            PackagingCredit: 'Packaging Credit',
            PackagingIssurance: 'Packaging Issurance',
            PkgNetReturnToGrower: 'Pkg Net Return To Grower',
            ShareGrowerAllowance: 'Share Grower Allowance',
            ShareGrowerDeduction: 'Share Grower Deduction',
            AllDeductNetReturnToGrower: 'All Deduct Net Return To Grower',
            SharePercentage: 'Share Percentage',
            ShareYunnan: 'Share Yunnan',
            ShareXishuangbanna: 'Share Xishuangbanna',
            ShareQujing: 'Share Qujing',
            ShareSevenknot: 'Share Seven knot KY',
            DriscollsYunnanAP: 'Driscolls Yunnan AP',
            DriscollsXishuangbannaAP: 'Driscolls Xishuangbanna AP',
            DriscollsQujingAP: 'Driscolls Qujing AP',
            DriscollsSevenknotAP: 'Seven knot KY AP',
            TotalCashToGrower: 'Final Net Return to Grower'
        },
        avgPriceReportColumnsMapping: {
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            ReportType: 'Report Type',
            PrelimWODamageAverageSettlementPrice: 'Prelim WODamage Average Settlement Price',
            PrelimAverageSettlementPrice: 'Prelim Average Settlement Price',
            AbnormalSalesAmount: 'Abnormal Sales Amount',
            SettlementUnitPrice: 'Settlement Unit Price',
            RepackagingSalesQty: 'Repackaging Sales Qty',
            FinalTrueUpAverageSettlementPrice: 'Final True Up Average Settlement Price',
            SoldQty: 'Sold Qty',
            ReceiptQty: 'Receipt Qty',
            EstimateGroup: 'Estimate Group',
            FinalAverageSettlementPrice: 'Final Average Settlement Price',
            SampleDamageSalesQty: 'Sample Damage Sales Qty',
            ClosingQty: 'Closing Qty',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            TurnOverRatioWK2: 'Turn Over Ratio WK2',
            OpeningQty: 'Opening Qty',
            OnHand: 'On Hand',
            TurnOverRatioWK1: 'Turn Over Ratio Wk1',
            TurnOverRatioWK3: 'Turn Over Ratio WK3',
            NormalSalesQty: 'Normal Sales Qty',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            // BusinessUnit: 'Business Unit',
            InTransit: 'In Transit',
            NormalSalesAmount: 'Normal Sales Amount',
            QualityIssueSalesQty: 'Quality Issue Sales Qty',
            AbnormalSalesUnitPrice: 'Abnormal Sales Unit Price',
            // PoolWeek: 'Pool Week',
            CreatedBy: 'Created By',
            CreatedDateTime: 'Created Date Time',
            ModifiedBy: 'Modified By',
            ModifiedDateTime: 'Modified Date Time'
        },
        weeklySalesReportColumns: {
            ItemID: 'Item ID',
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            PoolWeek: 'Pool Week',
            BusinessUnit: 'Business Unit',
            NormalSalesQty: 'Normal Sales Qty',
            NormalSalesAmount: 'Normal Sales Amount',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            DSharedSalesQty: 'D Shared Sales Qty',
            DSharedSalesAmount: 'D Shared Sales Amount',
            DSharedSalesUnitPrice: 'D Shared Sales Unit Unit Price',
            SampleDamageSalesQty: 'Sample Damage Sales Qty',
            QualityIssueSalesQty: 'Quality Issue Sales Qty',
            RepackagingSalesQty: 'Repackaging Sales Qty',
            ServiceDeductionAmount: 'Service Deduction Amount',
            SettlementUnitPrice: 'Settlement Unit Price',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            VarianceNormalSettlementUnitPrice: 'Variance Normal Settlement Unit Price',
            VarianceNormalSettlementUnitPriceWithRepack: 'Variance Normal Settlement Unit PriceWith Repack',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            AbnormalSalesAmount: 'Abnormal Sales Amount',
            AbnormalSalesUnitPrice: 'Abnormal Sales Unit Price',
            DisposalTotal: 'Disposal Total' // new
        },
        inventoryMovementReportColumns: {
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            LastWeekOnHand: 'Last Week On Hand',
            LastWeekInTransit: 'Last Week In Transit',
            LastWeekRepackSKUTransferBack: 'Last Week Repack SKU Transfer Back',
            // OpeningQty: 'Opening Qty',
            ReceiptQty: 'Receipt Qty',
            NormalSalesIncludeDshared: 'Normal Sales Qty', // renamed
            AbnormalSalesQty: 'Abnormal Sales Qty',
            DisposalQty: 'Disposal Qty',
            RepackMovementSalesQty: 'Repackaging Sales Qty', // renamed
            ClosingQty: 'Closing Qty',
            RepackSKUTransferBack: 'Repack SKU Transfer Back',
            OnHand: 'On Hand',
            InTransit: 'In Transit'
        },
        averageSalesPriceReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            FinalAverageSettlementPrice: 'Final Average Settlement Price',
            FinalTrueUpAverageSettlementPrice: 'Final True Up Average Settlement Price',
            PrelimWODamageAverageSettlementPrice: 'Prelim WO Damage Average Settlement Price'
        },
        turnoverRateReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            Spec: 'Spec',
            TurnOverRateWK1: 'Turn Over Rate WK1',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            TurnOverRateWK3: 'Turn Over Rate WK3'
        },
        inventoryOutboundReportColumns: {
            PoolWeek: 'Pool Week',
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            OpeningQty: 'Opening Qty',
            ReceiptQty: 'ReceiptQty',
            SoldQty: 'Sold Qty',
            NormalSalesQty: 'Normal Sales Qty',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            DisposalQty: 'Disposal Qty',
            ClosingQty: 'Closing Qty',
            SoldQtyWK1: 'Sold Qty WK1',
            TurnOverRateWK1: 'Turn Over Rate WK1',
            PrelimAverageSettlementPriceWK1: 'Prelim Average Settlement Price WK1',
            SoldQtyWK2: 'Sold Qty WK2',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            PrelimAverageSettlementPriceWK2: 'Prelim Average Settlement Price WK2',
            SoldQtyWK3: 'Sold Qty WK3',
            TurnOverRateWK3: 'Turn Over Rate WK3',
            PrelimAverageSettlementPriceWK3: 'Prelim Average Settlement Price WK3',
            FinalAverageSettlementPrice: 'Final Average Settlement Price'
        },
        agedInventoryReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Balance: 'Balance',
            ReceiptQty: 'Receipt Qty',
            SoldQty: 'Sold Qty'
        },
        settlementWorkBenchSubmitterColumns: {
            Status: { name: 'Status', width: '15%' },
            PricingPoolID: { name: 'Pricing Pool', width: '15%' },
            PoolID: { name: 'Pool Id', width: '15%' },
            ExpectedReceiptQty: { name: 'Expected Qty', width: '15%' },
            Description: { name: 'Description', width: '30%' },
            Reports: { name: 'Reports', width: '10%' }
        },
        settlementWorkBenchApproverColumns: {
            // Status: 'Status',
            // PoolWeek: 'Pricing Week',
            // PoolID: 'Pool Id',
            // SubmittedBy: 'Submitted By',
            // SubmittedDate: 'Submitted Date'
            Status: { name: 'Status', width: '15%' },
            PricingPoolID: { name: 'Pricing Pool', width: '15%' },
            PoolID: { name: 'Pool Id', width: '15%' },
            ExpectedReceiptQty: { name: 'Expected Qty', width: '15%' },
            Description: { name: 'Description', width: '30%' },
            Reports: { name: 'Reports', width: '10%' }
        },
        summaryDomesticColumns: {
            PaymentDate: 'Payment Date',
            PoolWeek: 'Pool Week',
            SettlementCurrency: 'Currency',
            Commodity: 'Commodity',
            Abbreviation: 'SPEC',
            ItemID: 'Item ID',
            CommissionRate: 'Commission Rate',
            TotalTraysReceived: 'Total Trays Received',
            TotalKilosReceived: 'Total Kilos Received',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            AveragePricePerTray: 'Average Price Per Tray',
            AveragePricePerKg: 'Average Price Per Kg',
            // GrossPoolAmount: 'Gross Pool Amount',
            PackageDeduction: 'Package Deduction',
            FreightDeduction: 'Freight Deduction',
            PkgNetFruitReturnPricePerKg: 'Pkg Net Fruit Return Price Per KG',
            NetFruitBeforeDAPshare: 'Net Fruit Before DAP share',
            // PkgNetFruitReturnPricePerKg: 'Net Fruit Return Price Per Kg',
            DAPShare: 'DMSH Share',
            CommissionNetPoolReturn: 'Commission Net Pool Return',
            CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price Per Kg',
            VATPercent: 'VAT Percent',
            VATShareGrower: 'VAT Share Grower',
            VATNetPoolReturn: 'VAT Net Pool Return',
            VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price Per Kg',
            ReportType: 'Report Type',
            EstimateGroup: 'Estimate Group',
            TrayToKilogram: 'TrayToKilogram',
            // UOMConversionRate: 'UOM Conversion Rate',
            PackageRate: 'Package Rate',
            FreightRate: 'Freight Rate',
            SubmittedBy: 'Submitted By',
            SubmittedDate: 'Submitted Date',
            ApprovedBy: 'Approved By',
            ApprovedDate: 'Approved Date'
        },
        costCenterColumns: {
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            GrowerID: 'Grower ID',
            GrowerName: 'Grower Name',
            CostCenter: 'Cost Center',
            Application: 'Application',
            Location: 'Location',
            TotalTraysReceived: 'Total Trays Received',
            CommissionRate: 'Commission Rate',
            Category: 'Grower Category', // renamed
            FamilyEntity: 'Family Entity',
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week'
        }
    },
    DEMEA: {
        receiptLinesColumns: {
            // VendorID: 'VENDOR ACCOUNT',
            ReceiptID: 'Receipt Id',
            LineNum: 'Line',
            TransDate: 'Date',
            VendorNameChinese: 'Vendor Name Chinese',
            VendorName: 'Vendor Name',
            ItemID: 'Item Number',
            Commodity: 'Commodity',
            Quantity: 'Quantity',
            CostCenter: 'Cost Center',
            Variety: 'Variety',
            Application: 'Application',
            QtyOrdered: 'Qty Ordered',
            Memo: 'Memo',
            Location: 'Location',
            QCScore: 'QC Score',
            PoolWeek: 'Pool Week',
            ModifiedDateTime: 'Modified Date Time'
        },
        salesInvoiceLineColumns: {
            InvoiceID: 'Invoice ID',
            LineNum: 'Line Num',
            InvoiceDate: 'Invoice Date',
            ItemID: 'Item ID',
            ItemDescription: 'Item Description',
            PickupLocation: 'Pickup Location',
            InventTransID: 'Invent Trans ID',
            Quantity: 'Quantity',
            InvoiceAmount: 'Invoice Amount',
            CustomerCode: 'Customer Code',
            CustomerName: 'Customer Name',
            CustomerGeography: 'Customer Geography',
            Category: 'Category',
            ItemRate: 'Item Rate',
            Memo: 'Memo',
            SalesID: 'Sales ID',
            PoolWeek: 'Pool Week',
            ModifiedDateTime: 'Modified Date Time'
            // VendorID: 'Vendor Account',
            // ReceiptID: 'Receipt ID',
            // ContractID: 'Contract ID',
            // CommissionPercent: 'Commission %',
            // FixedFeeCostAmount: 'Fixed Cost Amount',
            // BusinessUnit: 'Business Unit',
            // SalesInvoiceID: 'Sales Invoice ID',
        },
        inventoryOnHandColumns: {
            ItemID: 'Item',
            Description: 'Description',
            PreferredVendor: 'Preferred Vendor',
            OnHand: 'On Hand',
            PoolWeek: 'Pool Week',
            TransactionKey: 'Transaction Key',
            BusinessUnit: 'Business Unit',
            LastModifiedDate: 'Modified Date Time'
        },
        goodsInTransitColumns: {
            ItemID: 'Item ID',
            ItemDescription: 'Item Description',
            TransactionKey: 'Document Number',
            InventoryStatus: 'Status',
            ShipToLocation: 'Ship To',
            Memo: 'Quantity Memo',
            DateShipped: 'Date Shipped',
            DateReceived: 'Date Received',
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week',
            Quantity: 'Quantity',
            ShipFromLocation: 'Ship From Location',
            LastModifiedDate: 'Last Modified Date'
        },
        ServiceDeductionColumns: {
            RefItemID: 'Item Id',
            Account: 'Account',
            Amount: 'Amount',
            BerryType: 'Berry Type',
            Date: 'Date',
            Memo: 'Memo',
            PoolWeek: 'Pool Week',
            TransactionKey: 'Transaction Key',
            TransactionNumber: 'Transaction Number',
            Type: 'Type',
            Vendor: 'Vendor',
            BusinessUnit: 'Business Unit',

            LastModifiedDate: 'Last Modified Date'
        },
        settlementReportColumns: {
            ItemID: 'Item ID',
            GrowerID: 'Grower ID',
            Commodity: 'Commodity',
            PaymentDate: 'Payment Date',
            ReportType: 'Report Type',
            TotalKilosReceived: 'Total Kilos Received',
            FreightRate: 'Freight Rate',
            PackageDeduction: 'Package Deduction',
            GrossPoolAmount: 'Gross Pool Amount',
            CommissionNetPoolReturn: 'Commission Net Pool Return',
            FreightDeduction: 'Freight Deduction',
            CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price Per Kg',
            AveragePricePerKg: 'Average Price Per Kg',
            VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price Per Kg',
            PackageRate: 'Package Rate',
            UOMConversionRate: 'UOM Conversion Rate',
            // ApprovedDate: 'Approved Date',
            // SubmittedDate: 'Submitted Date',
            VATNetPoolReturn: 'VAT Net Pool Return',
            AveragePricePerTray: 'Average Price Per Tray',
            VATPercent: 'VAT Percent',
            NetFruitReturnPricePerKg: 'NetFruit Return Price Per Kg',
            TotalTraysReceived: 'Total Trays Received',
            Version: 'Version',
            VATShareGrower: 'VAT Share Grower',
            // BusinessUnit: 'Business Unit',
            CommissionRate: 'Commission Rate',
            SettlementCurrency: 'Settlement Currency',
            NetFruitBeforeDAPshare: 'Net Fruit Before DAP share',
            PoolWeek: 'Pool Week',
            DAPShare: 'DAP Share',
            SubmittedBy: 'Submitted By',
            ApprovedBy: 'Approved By',
            CreatedBy: 'Created By',
            CreatedDateTime: 'Created Date Time',
            ModifiedBy: 'Modified By',
            ModifiedDateTime: 'Modified Date Time'
        },
        growerSettlementReport: {
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week',
            Commodity: 'Commodity',
            Abbreviation: 'SPEC',
            ItemID: 'Item ID',
            CommissionRate: 'Commission Rate',
            TrayToKilogram: 'Tray To Kilogram',
            PackageRate: 'Package Rate',
            Category: 'Category',
            FamilyEntity: 'Family Entity',
            GrowerID: 'Grower ID',
            GrowerName: 'Grower Name',
            TotalTraysReceived: 'Total Trays Received',
            TotalKilosReceived: 'Total Kilos Received',
            ReportType: 'Report Type',
            NetFruitReturnPricePerKg: 'Net Fruit Return Price/Kilo (Avg)',
            NetReturnToGrower: 'Net Return To Grower',
            PackagingCredit: 'Packaging Credit',
            PackagingIssurance: 'Packaging Issurance',
            PkgNetReturnToGrower: 'Pkg Net Return To Grower',
            ShareGrowerAllowance: 'Share Grower Allowance',
            ShareGrowerDeduction: 'Share Grower Deduction',
            AllDeductNetReturnToGrower: 'All Deduct Net Return To Grower',
            SharePercentage: 'Share Percentage',
            ShareYunnan: 'Share Yunnan',
            ShareXishuangbanna: 'Share Xishuangbanna',
            ShareQujing: 'Share Qujing',
            ShareSevenknot: 'Share Seven knot KY',
            DriscollsYunnanAP: 'Driscolls Yunnan AP',
            DriscollsXishuangbannaAP: 'Driscolls Xishuangbanna AP',
            DriscollsQujingAP: 'Driscolls Qujing AP',
            DriscollsSevenknotAP: 'Seven knot KY AP',
            TotalCashToGrower: 'Final Net Return to Grower'
        },
        avgPriceReportColumnsMapping: {
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            ReportType: 'Report Type',
            PrelimWODamageAverageSettlementPrice: 'Prelim WODamage Average Settlement Price',
            PrelimAverageSettlementPrice: 'Prelim Average Settlement Price',
            AbnormalSalesAmount: 'Abnormal Sales Amount',
            SettlementUnitPrice: 'Settlement Unit Price',
            RepackagingSalesQty: 'Repackaging Sales Qty',
            FinalTrueUpAverageSettlementPrice: 'Final True Up Average Settlement Price',
            SoldQty: 'Sold Qty',
            ReceiptQty: 'Receipt Qty',
            EstimateGroup: 'Estimate Group',
            FinalAverageSettlementPrice: 'Final Average Settlement Price',
            SampleDamageSalesQty: 'Sample Damage Sales Qty',
            ClosingQty: 'Closing Qty',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            TurnOverRatioWK2: 'Turn Over Ratio WK2',
            OpeningQty: 'Opening Qty',
            OnHand: 'On Hand',
            TurnOverRatioWK1: 'Turn Over Ratio Wk1',
            TurnOverRatioWK3: 'Turn Over Ratio WK3',
            NormalSalesQty: 'Normal Sales Qty',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            // BusinessUnit: 'Business Unit',
            InTransit: 'In Transit',
            NormalSalesAmount: 'Normal Sales Amount',
            QualityIssueSalesQty: 'Quality Issue Sales Qty',
            AbnormalSalesUnitPrice: 'Abnormal Sales Unit Price',
            // PoolWeek: 'Pool Week',
            CreatedBy: 'Created By',
            CreatedDateTime: 'Created Date Time',
            ModifiedBy: 'Modified By',
            ModifiedDateTime: 'Modified Date Time'
        },
        weeklySalesReportColumns: {
            ItemID: 'Item ID',
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            PoolWeek: 'Pool Week',
            BusinessUnit: 'Business Unit',
            NormalSalesQty: 'Normal Sales Qty',
            NormalSalesAmount: 'Normal Sales Amount',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            DSharedSalesQty: 'D Shared Sales Qty',
            DSharedSalesAmount: 'D Shared Sales Amount',
            DSharedSalesUnitPrice: 'D Shared Sales Unit Unit Price',
            SampleDamageSalesQty: 'Sample Damage Sales Qty',
            QualityIssueSalesQty: 'Quality Issue Sales Qty',
            RepackagingSalesQty: 'Repackaging Sales Qty',
            ServiceDeductionAmount: 'Service Deduction Amount',
            SettlementUnitPrice: 'Settlement Unit Price',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            VarianceNormalSettlementUnitPrice: 'Variance Normal Settlement Unit Price',
            VarianceNormalSettlementUnitPriceWithRepack: 'Variance Normal Settlement Unit PriceWith Repack',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            AbnormalSalesAmount: 'Abnormal Sales Amount',
            AbnormalSalesUnitPrice: 'Abnormal Sales Unit Price',
            DisposalTotal: 'Disposal Total' // new
        },
        inventoryMovementReportColumns: {
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            LastWeekOnHand: 'Last Week On Hand',
            LastWeekInTransit: 'Last Week In Transit',
            LastWeekRepackSKUTransferBack: 'Last Week Repack SKU Transfer Back',
            // OpeningQty: 'Opening Qty',
            ReceiptQty: 'Receipt Qty',
            NormalSalesIncludeDshared: 'Normal Sales Qty', // renamed
            AbnormalSalesQty: 'Abnormal Sales Qty',
            DisposalQty: 'Disposal Qty',
            RepackMovementSalesQty: 'Repackaging Sales Qty', // renamed
            ClosingQty: 'Closing Qty',
            RepackSKUTransferBack: 'Repack SKU Transfer Back',
            OnHand: 'On Hand',
            InTransit: 'In Transit'
        },
        averageSalesPriceReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            SettlementUnitPriceWithRepack: 'Settlement Unit Price With Repack',
            FinalAverageSettlementPrice: 'Final Average Settlement Price',
            FinalTrueUpAverageSettlementPrice: 'Final True Up Average Settlement Price',
            PrelimWODamageAverageSettlementPrice: 'Prelim WO Damage Average Settlement Price'
        },
        turnoverRateReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Commodity: 'Commodity',
            Spec: 'Spec',
            TurnOverRateWK1: 'Turn Over Rate WK1',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            TurnOverRateWK3: 'Turn Over Rate WK3'
        },
        inventoryOutboundReportColumns: {
            PoolWeek: 'Pool Week',
            EstimateGroup: 'Estimate Group',
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            OpeningQty: 'Opening Qty',
            ReceiptQty: 'ReceiptQty',
            SoldQty: 'Sold Qty',
            NormalSalesQty: 'Normal Sales Qty',
            AbnormalSalesQty: 'Abnormal Sales Qty',
            DisposalQty: 'Disposal Qty',
            ClosingQty: 'Closing Qty',
            SoldQtyWK1: 'Sold Qty WK1',
            TurnOverRateWK1: 'Turn Over Rate WK1',
            PrelimAverageSettlementPriceWK1: 'Prelim Average Settlement Price WK1',
            SoldQtyWK2: 'Sold Qty WK2',
            TurnOverRateWK2: 'Turn Over Rate WK2',
            PrelimAverageSettlementPriceWK2: 'Prelim Average Settlement Price WK2',
            SoldQtyWK3: 'Sold Qty WK3',
            TurnOverRateWK3: 'Turn Over Rate WK3',
            PrelimAverageSettlementPriceWK3: 'Prelim Average Settlement Price WK3',
            FinalAverageSettlementPrice: 'Final Average Settlement Price'
        },
        agedInventoryReportColumns: {
            PoolWeek: 'Pool Week',
            ItemID: 'Item ID',
            Balance: 'Balance',
            ReceiptQty: 'Receipt Qty',
            SoldQty: 'Sold Qty'
        },
        settlementWorkBenchSubmitterColumns: {
            Status: { name: 'Status', width: '15%' },
            PricingPoolID: { name: 'Pricing Pool', width: '15%' },
            PoolID: { name: 'Pool Id', width: '15%' },
            ExpectedReceiptQty: { name: 'Expected Qty', width: '15%' },
            Description: { name: 'Description', width: '30%' },
            Reports: { name: 'Reports', width: '10%' }
        },
        settlementWorkBenchApproverColumns: {
            // Status: 'Status',
            // PoolWeek: 'Pricing Week',
            // PoolID: 'Pool Id',
            // SubmittedBy: 'Submitted By',
            // SubmittedDate: 'Submitted Date'
            Status: { name: 'Status', width: '15%' },
            PricingPoolID: { name: 'Pricing Pool', width: '15%' },
            PoolID: { name: 'Pool Id', width: '15%' },
            ExpectedReceiptQty: { name: 'Expected Qty', width: '15%' },
            Description: { name: 'Description', width: '30%' },
            Reports: { name: 'Reports', width: '10%' }
        },
        summaryDomesticColumns: {
            PaymentDate: 'Payment Date',
            PoolWeek: 'Pool Week',
            SettlementCurrency: 'Currency',
            Commodity: 'Commodity',
            Abbreviation: 'SPEC',
            BusinessUnit: 'Business Unit',
            ItemID: 'Item ID',
            CommissionRate: 'Commission Rate',
            TotalTraysReceived: 'Total Trays Received',
            TotalKilosReceived: 'Total Kilos Received',
            NormalSalesUnitPrice: 'Normal Sales Unit Price',
            AveragePricePerTray: 'Average Price Per Tray',
            AveragePricePerKg: 'Average Price Per Kg',
            TrayToKilogram: 'Tray To Kilogram',
            // GrossPoolAmount: 'Gross Pool Amount',
            PackageDeduction: 'Package Deduction',
            FreightDeduction: 'Freight Deduction',
            NetFruitBeforeDAPshare: 'Net Fruit Before DAP share',
            PkgNetFruitReturnPricePerKg: 'Pkg Net Fruit Return Price Per Kg',
            DAPShare: 'DMSH Share',
            CommissionNetPoolReturn: 'Commission Net Pool Return',
            CommissionNetFruitReturnPricePerKg: 'Commission Net Fruit Return Price Per Kg',
            VATPercent: 'VAT Percent',
            VATShareGrower: 'VAT Share Grower',
            VATNetPoolReturn: 'VAT Net Pool Return',
            VATNetFruitReturnPricePerKg: 'VAT Net Fruit Return Price Per Kg',
            ReportType: 'Report Type',
            EstimateGroup: 'Estimate Group',
            // UOMConversionRate: 'UOM Conversion Rate',
            PackageRate: 'Package Rate',
            FreightRate: 'Freight Rate',
            SubmittedBy: 'Submitted By',
            SubmittedDate: 'Submitted Date',
            ApprovedBy: 'Approved By',
            ApprovedDate: 'Approved Date'
        },
        costCenterColumns: {
            Commodity: 'Commodity',
            ItemID: 'Item ID',
            GrowerID: 'Grower ID',
            GrowerName: 'Grower Name',
            CostCenter: 'Cost Center',
            Application: 'Application',
            Location: 'Location',
            TotalTraysReceived: 'Total Trays Received',
            CommissionRate: 'Commission Rate',
            Category: 'Grower Category', // renamed
            FamilyEntity: 'Family Entity',
            BusinessUnit: 'Business Unit',
            PoolWeek: 'Pool Week'
        }
    }
};
