import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'JeShReport',
        [
            {
                name: 'Code',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CostCenter',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'QuantityKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Blackberry',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Blueberry',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Raspberry',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Strawberry',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ReportHeader',
                type: FIELD_UNITS.STRING
            }
        ],
        {},
        (data) => {
            console.log('JE SH REDUCER-- ', data);
        }
    );
}

const JeShReport = {
    configureSetupCall
};

export default JeShReport;
