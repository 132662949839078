import { designColors } from '../data/constants';

const styles = (theme, height, maxHeight) => {
    return {
        '& .p-datatable-thead': {
            background: 'hsl(0, 0%, 85%)',
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['600']} !important`
            }
        },
        '& .p-datatable-row.even': {
            backgroundColor: `${theme.light.grid.even} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.even} !important`
            }
        },
        '& .p-datatable-row.odd': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.odd} !important`
            }
        },
        '& .p-datatable-row:nth-of-type(2n+1)': {
            borderTop: '1px solid #dddddd',
            borderBottom: '1px solid #dddddd',
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-datatable-row:nth-of-type(2n)': {
            borderTop: '1px solid #dddddd',
            borderBottom: '1px solid #dddddd',
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-selectable-row': {
            backgroundColor: `${theme.light.grid.even} `,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.even} `
            }
        },
        '& .p-row-odd': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.odd} !important`
            }
        },
        '& .p-datatable-scrollable-header': {
            background: 'none',
            [theme.darkTheme]: {
                borderBottom: 'solid 1px ' + theme.light.text.primary,
                color: theme.palette.common.black
            }
        },
        '& .p-datatable-scrollable-header-box': {
            marginRight: '0px !important'
        },
        '& .p-row:nth-of-type(2n+1)': {
            borderTop: '1px solid ' + theme.light.text.variant.secondary,
            borderBottom: '1px solid ' + theme.light.text.variant.secondary,
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-row:nth-of-type(2n)': {
            borderTop: '1px solid ' + theme.light.text.variant.secondary,
            borderBottom: '1px solid ' + theme.light.text.variant.secondary,
            [theme.darkTheme]: {
                borderTop: '1px solid ' + theme.dark.primary,
                borderBottom: '1px solid ' + theme.dark.primary
            }
        },
        '& .p-datatable-scrollable-wrapper': {
            borderRadius: '4px',
            overflow: 'hidden',
            borderTop: 'solid 1px ' + theme.light.text.variant.secondary,
            borderLeft: 'solid 1px ' + theme.light.text.variant.secondary,
            borderRight: 'solid 1px ' + theme.light.text.variant.secondary,
            borderBottom: 'solid 1px ' + theme.light.text.variant.secondary,
            [theme.darkTheme]: {
                borderTop: 'solid 1px ' + theme.dark.text.variant.secondary,
                borderLeft: 'solid 1px ' + theme.dark.text.variant.secondary,
                borderRight: 'solid 1px ' + theme.dark.text.variant.secondary,
                borderBottom: 'solid 1px ' + theme.dark.text.variant.secondary
            }
        },
        '& .p-datatable-wrapper': {
            maxHeight: maxHeight ? maxHeight : 'calc(100vh - 280px)',
            borderRadius: '4px',
            overflow: 'auto',
            borderTop: 'solid 1px ' + theme.light.text.variant.secondary,
            borderLeft: 'solid 1px ' + theme.light.text.variant.secondary,
            borderRight: 'solid 1px ' + theme.light.text.variant.secondary,
            borderBottom: 'solid 1px ' + theme.light.text.variant.secondary,
            [theme.darkTheme]: {
                borderTop: 'solid 1px ' + theme.dark.text.variant.secondary,
                borderLeft: 'solid 1px ' + theme.dark.text.variant.secondary,
                borderRight: 'solid 1px ' + theme.dark.text.variant.secondary,
                borderBottom: 'solid 1px ' + theme.dark.text.variant.secondary
            }
        },
        '& .p-datatable-scrollable-body': {
            height: 'calc(100vh - 280px)' // height ? height :
        },
        '& .p-inputtext.p-component.p-column-filter': {
            borderRadius: '4px'
        },
        '& .p-column-title': {
            '& span': {
                fontWeight: '600',
                whiteSpace: 'nowrap'
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            float: 'left',
            textAlign: 'left',
            width: '130px'
        },
        '& .p-dropdown label.p-dropdown-label': {
            textOverflow: 'ellipsis',
            width: '88%'
        },
        '& .p-datatable-tbody tr td': {
            fontSize: '13px',
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        '& .p-datatable-tbody tr': {
            height: 48
        },
        '& .p-datatable-thead tr': {
            height: 40
        },
        '& .p-paginator': { display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: '0.4em' },
        '& .p-paginator-left-content': { marginRight: 'auto' },
        '& .p-paginator-right-content': { marginLeft: 'auto' },
        '& .p-paginator-page': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: 3,
            justifyContent: 'center',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: theme.light.text.primary,
            minWidth: '2.357rem',
            height: '2.357rem',
            fontSize: '1.2rem',
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            }
        },
        '& .p-paginator-page.p-link.p-highlight': {
            textDecoration: 'underline',
            fontWeight: 'bold'
        },
        '& .p-paginator .p-link.p-disabled': {
            opacity: 0.4
        },
        '& .p-paginator-next': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            }
        },
        '& .p-paginator-last': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            }
        },
        '& .p-paginator-first': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            }
        },
        '& .p-paginator-prev': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: theme.light.text.primary,
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            }
        },
        '& .p-paginator-current': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '2',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: theme.light.text.primary,
            fontSize: '14px',
            [theme.darkTheme]: {
                color: theme.palette.grey['A200']
            }
        },
        '& .p-paginator-element-focus': { zIndex: '1', position: 'relative' },
        '& .p-paginator .p-paginator-pages .p-paginator-page.p-highlight': {
            backgroundColor: `${designColors.blue}`,
            color: '#fff'
        },
        '& .pi': {
            fontSize: '18px'
        },
        '& .p-checkbox .p-checkbox-box': {
            width: '1.125em',
            height: '1.125em',
            lineHeight: '1.125em',
            borderRadius: '2px',
            textAlign: 'center',
            border: '1px solid',
            padding: '0px 18px 18px 0px'
        },
        '& .checkboxDisabled  .p-checkbox .p-checkbox-box': {
            border: `1px solid ${theme.palette.grey['A200']}`,
            opacity: '0.5',
            cursor: 'default'
        },
        '& .p-checkbox .p-checkbox-icon': {
            cursor: 'default',
            display: 'block !important'
        },
        '& .p-checkbox-box': {
            display: 'block !important',
            marginLeft: '10px'
        }
    };
};

const GridStyles = {
    styles: styles
};

export default GridStyles;
