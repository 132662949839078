import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'RepackageMovement',
        [
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'RepackageItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'OriginalItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'OpeningBalanceKG',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ClosingBalanceKG',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SalesOrderSold',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ReceiptQty',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'IncludeQuality',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'DamageLoss',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Remark',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CustomerCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'UserId',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'RepackageMovementID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'SalesQty',
                type: FIELD_UNITS.RAW
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Repackage Movement REDUCER-- ', data);
        }
    );
}

const RepackageMovement = {
    configureSetupCall
};

export default RepackageMovement;
