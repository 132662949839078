import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'DomesticSummary',
        [
            {
                name: 'SubmittedBy',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PaymentDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'TotalKilosReceived',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PackageDeduction',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'TrayToKilogram',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrossPoolAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ReportType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FreightRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'CommissionNetPoolReturn',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FreightDeduction',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ItemID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'EstimateGroup',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CommissionNetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AveragePricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VATNetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PackageRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            // {
            //     name: 'UOMConversionRate',
            //     type: FIELD_UNITS.NUMBER,
            //     options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            // },
            {
                name: 'ApprovedDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'VATNetPoolReturn',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NormalSalesUnitPrice',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AveragePricePerTray',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VATPercent',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'TotalTraysReceived',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Abbreviation',
                type: FIELD_UNITS.STRING
                // options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'VATShareGrower',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'CommissionRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'PkgNetFruitReturnPricePerKg',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'NetFruitBeforeDAPshare',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'DAPShare',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'SettlementCurrency',
                type: FIELD_UNITS.STRING
            },

            {
                name: 'SubmittedDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'ApprovedBy',
                type: FIELD_UNITS.STRING
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('REDUCER-- ', data);
        }
    );
}

const DomesticSummary = {
    configureSetupCall
};

export default DomesticSummary;
