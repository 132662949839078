import { Middleware } from '@driscollsinc/one-ring';
const getPoolWeekDefMapping = [
    Middleware.CreateMapping('PoolWeek', 'PoolWeek'),
    Middleware.CreateMapping('PoolWeekStartDate', 'PoolWeekStartDate'),
    Middleware.CreateMapping('PoolWeekEndDate', 'PoolWeekEndDate'),
    Middleware.CreateMapping('BusinessUnit', 'BusinessUnit'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedTime', 'ModifiedTime')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PoolWeekDefinition', 'GET')) {
        Middleware.RegisterMapping('PoolWeekDefinition', 'GET', getPoolWeekDefMapping);
    }
}

const PoolWeekDefinitionMapping = {
    ConfigureMiddleware
};

export default PoolWeekDefinitionMapping;
