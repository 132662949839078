import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'WeeklySettlementStatusList',
        [
            {
                name: 'ActualReceiptQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Adjustments',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ExpectedReceiptQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ImportCost',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'InTransitQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'InvOnHandQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'RepackageSalesQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SalesAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'ServiceDeductionAmount',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'SoldQty',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },

            {
                name: 'PricingPoolDescription',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PoolID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PricingPoolID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Status',
                type: FIELD_UNITS.STRING
            }
        ],
        {}, //options
        (data) => {
            console.log('REDUCER-- ', data);
        }
    );
}

const WeeklySettlementStatus = {
    configureSetupCall
};

export default WeeklySettlementStatus;
