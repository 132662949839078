export const VENDOR_CODE_OPTIONS = 'VENDOR_CODE_OPTIONS';
export const vendorCodeOptions = (data) => ({
    type: VENDOR_CODE_OPTIONS,
    payload: data
});

export const PARENT_VENDOR_CODE_OPTIONS = 'PARENT_VENDOR_CODE_OPTIONS';
export const parentVendorCodeOptions = (data) => ({
    type: PARENT_VENDOR_CODE_OPTIONS,
    payload: data
});
