import { SetupCall } from '@driscollsinc/driscolls-display-rules';
import { LOOKUP_FIELDS } from '../constants';
import { lookupSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        LOOKUP_FIELDS.EstimateGroupLookUp,
        [...lookupSetupFields],
        {}, //options
        (data) => {
            console.log('EstimateGroupLookUp REDUCER-- ', data);
        }
    );
}

const EstimateGroupLookUp = {
    configureSetupCall
};

export default EstimateGroupLookUp;
