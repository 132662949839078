import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'ManualAdjustment',
        [
            {
                name: 'GrowerID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Category',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Comments',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Currency',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'AdjustmentAmount',
                type: FIELD_UNITS.NUMBER
            },
            {
                name: 'BeforeCalculation',
                type: FIELD_UNITS.STRING
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Amount Adjustment REDUCER-- ', data);
        }
    );
}

const AmountAdjustment = {
    configureSetupCall
};

export default AmountAdjustment;
