import { Middleware } from '@driscollsinc/one-ring';
const getItemUomConversionMapping = [
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('ItemDescription', 'ItemDescription'),
    Middleware.CreateMapping('EstimateGroup', 'EstimateGroup'),
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('TrayToKg', 'TrayToKg'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ItemUomConversion', 'GET')) {
        Middleware.RegisterMapping('ItemUomConversion', 'GET', getItemUomConversionMapping);
    }
}

const ItemUomConversionMapping = {
    ConfigureMiddleware
};

export default ItemUomConversionMapping;
