import { commodityOptions, currencyOptions, reportTypeOptions, versionOptions } from '../data/constants';

const emptyFilters = {
    GrowerSettlementID: { value: '', label: '', index: -2, isSet: false },
    ItemID: { value: '', label: '', index: -3, isSet: false },
    Commodity: { value: '', label: '', index: -4, isSet: false },
    CommissionRate: { value: '', label: '', index: -5, isSet: false },
    // PoolWeek: { value: '', label: '', index: -6, isSet: false },
    SettlementCurrency: { value: '', label: '', index: -7, isSet: false },
    ReportType: { value: '', label: '', index: -8, isSet: false },
    Version: { value: '', label: '', index: -9, isSet: false },
    UOMConversionRate: { value: '', label: '', index: -10, isSet: false },
    TotalKilosReceived: { value: '', label: '', index: -11, isSet: false },
    AvgPricePerTray: { value: '', label: '', index: -12, isSet: false }
};
const defaultFilters = {
    GrowerSettlementID: { value: '', label: '', index: -2, isSet: false },
    ItemID: { value: '', label: '', index: -3, isSet: false },
    Commodity: { value: '', label: '', index: -4, isSet: false },
    CommissionRate: { value: '', label: '', index: -5, isSet: false },
    // PoolWeek: { value: '', label: '', index: -6, isSet: false },
    SettlementCurrency: { value: '', label: '', index: -7, isSet: false },
    ReportType: { value: '', label: '', index: -8, isSet: false },
    Version: { value: '', label: '', index: -9, isSet: false },
    UOMConversionRate: { value: '', label: '', index: -10, isSet: false },
    TotalKilosReceived: { value: '', label: '', index: -11, isSet: false },
    AvgPricePerTray: { value: '', label: '', index: -12, isSet: false }
};

export const filterNames = {
    GrowerSettlementID: 'GrowerSettlementID',
    ItemID: 'ItemID',
    Commodity: 'Commodity',
    CommissionRate: 'CommissionRate',
    // PoolWeek: 'PoolWeek',
    SettlementCurrency: 'SettlementCurrency',
    ReportType: 'ReportType',
    Version: 'Version',
    UOMConversionRate: 'UOMConversionRate',
    TotalKilosReceived: 'TotalKilosReceived',
    AvgPricePerTray: 'AvgPricePerTray'
};
var masterData = (masterData) => ({
    GrowerSettlementID: [],
    ItemID: [],
    Commodity: commodityOptions,
    CommissionRate: [],
    // PoolWeek: (masterData.yearPoolWeeks || []).map((itm) => ({ label: itm.PoolWeek, value: itm.PoolWeek, ...itm })),
    SettlementCurrency: currencyOptions,
    ReportType: reportTypeOptions,
    Version: versionOptions,
    UOMConversionRate: [],
    TotalKilosReceived: [],
    AvgPricePerTray: []
});
const FilterUtilities = {
    EmptyFilters: emptyFilters,
    DefaultFilters: defaultFilters,
    DefaultGridColumns: [],
    MasterData: masterData
};
export default FilterUtilities;
