import { Middleware } from '@driscollsinc/one-ring';
const getCommoditiesTableMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('CommoditiesTable', 'GET')) {
        Middleware.RegisterMapping('CommoditiesTable', 'GET', getCommoditiesTableMapping);
    }
}

const CommoditiesTableMapping = {
    ConfigureMiddleware
};

export default CommoditiesTableMapping;
