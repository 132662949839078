import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'RepackagingSkuMapping',
        [
            {
                name: 'OriginalItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'OriginalItemDescription',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'RepackageItemMapID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'RepackageItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'CustomerCode',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'RepackageItemDescription',
                type: FIELD_UNITS.STRING
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Repackaging Sku Mapping REDUCER-- ', data);
        }
    );
}

const RepackagingSkuMapping = {
    configureSetupCall
};

export default RepackagingSkuMapping;
