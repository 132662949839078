export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_CURRENT_POOL_WEEk = 'SET_CURRENT_POOL_WEEk';
export const setCurrentPoolWeek = (poolWeek) => ({
    type: SET_CURRENT_POOL_WEEk,
    payload: poolWeek
});

export const SET_CURRENT_YEAR_POOL_WEEk = 'SET_CURRENT_YEAR_POOL_WEEk';
export const setCurrentYearPoolWeek = (poolWeek) => ({
    type: SET_CURRENT_YEAR_POOL_WEEk,
    payload: poolWeek
});

export const SET_LOOKUP_VALUES = 'SET_LOOKUP_VALUES';
export const setLookUpValues = (lookup = '', value = []) => ({
    type: SET_LOOKUP_VALUES,
    payload: { lookup, value }
});

export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const setUserDetails = (lang = 'en-US', profile = {}) => ({
    type: SET_USER_DETAIL,
    payload: { lang, profile }
});

export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
export const setUserPreference = (userData) => ({
    type: SET_USER_PREFERENCE,
    payload: userData
});

export const SET_USER_LANG = 'SET_USER_LANG';
export const setUserLang = (lang = 'en-US') => ({
    type: SET_USER_LANG,
    payload: lang
});
