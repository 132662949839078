import WeeklySettlementStatus from './weeklySettlementStatus';
import SettlementWorkbench from './settlementWorkbench';
import PoolWeekDefinition from './PoolWeekDefinition';
import CustomerCategory from './CustomerCategory';
import ItemUomConversion from './itemUomConversion';
import ItemShippingRates from './ItemShippingRates';
import GrowerCategory from './GrowerCategory';
import SettlementRate from './SettlementRate';
import RepackagingSkuMapping from './RepackagingSkuMapping';
import CommoditiesTable from './CommoditiesTable';

function ConfigureAllMiddleware() {
    WeeklySettlementStatus.ConfigureMiddleware();
    SettlementWorkbench.ConfigureMiddleware();
    PoolWeekDefinition.ConfigureMiddleware();
    CustomerCategory.ConfigureMiddleware();
    ItemUomConversion.ConfigureMiddleware();
    ItemShippingRates.ConfigureMiddleware();
    GrowerCategory.ConfigureMiddleware();
    SettlementRate.ConfigureMiddleware();
    RepackagingSkuMapping.ConfigureMiddleware();
    CommoditiesTable.ConfigureMiddleware();
}

const Middleware = {
    ConfigureAllMiddleware
};

export default Middleware;
