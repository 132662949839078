import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'ShipmentRate',
        [
            {
                name: 'ShipmentNumber',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CustomClearanceFee',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'GrowerID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'Trays',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            },
            {
                name: 'ContainerNumber',
                type: FIELD_UNITS.STRING
            },

            {
                name: 'FreightCustomToFDC',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'RepackagingCharge',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },

            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'ImportCostID',
                type: FIELD_UNITS.RAW
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Shipment Rate REDUCER-- ', data);
        }
    );
}

const ShipmentRate = {
    configureSetupCall
};

export default ShipmentRate;
