import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'JournalAccount',
        [
            {
                name: 'LookupId',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'AccountNumber',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'TransactionType',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'LineMemo',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'AccountName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ReportType',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'DisplayOrder',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'EntityType',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Entity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Comments',
                type: FIELD_UNITS.STRING
            },
            ...dateTimeSetupFields
        ],
        {},
        (data) => {
            console.log('JournalAccount REDUCER-- ', data);
        }
    );
}

const JournalAccount = {
    configureSetupCall
};

export default JournalAccount;
