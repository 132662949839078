export const SET_COMMON_DIALOG = 'SET_COMMON_DIALOG';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const LOGOUT = 'LOGOUT';

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';

export const SHOW_REPORTBACKLINK = 'SHOW_REPORTBACKLINK';

export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_USER_IS_ADMIN = 'SET_USER_IS_ADMIN';

export const SERVICE_WORKER_UPDATE = 'SERVICE_WORKER_UPDATE';

// Master Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

// Modal Reducers
export const setCommonDialogAction = (title, errors, isMessage) => ({
    type: SET_COMMON_DIALOG,
    payload: { title, errors, isMessage }
});

export const hideCommonDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const logoutAction = () => ({
    type: LOGOUT,
    payload: {}
});

export const showLoadingScreenAction = (loadingMessage) => ({
    type: SHOW_LOADING_SCREEN,
    payload: loadingMessage
});

export const showReportBackLinkAction = (enabled) => ({
    type: SHOW_REPORTBACKLINK,
    payload: enabled
});

export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});

export const setUserRoles = (value) => ({
    type: SET_USER_ROLES,
    payload: value
});

export const setUserIsAdmin = (value) => ({
    type: SET_USER_IS_ADMIN,
    payload: value
});

export const SHOW_TOAST = 'SHOW_TOAST';
export const showToast = (toastMessage, isSuccess) => ({
    type: SHOW_TOAST,
    payload: { toastMessage, isSuccess }
});

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const openDrawer = (bool = false) => ({
    type: OPEN_DRAWER,
    payload: bool
});

//This action dismisses the new version dialog
export const SERVICE_WORKER_UPDATED = 'SERVICE_WORKER_UPDATED';
export const serviceWorkerUpdate = () => ({
    type: SERVICE_WORKER_UPDATED
});
