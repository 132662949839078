import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';
import { dateTimeSetupFields } from './CommonSetupFields';

function configureSetupCall() {
    SetupCall(
        'ItemShippingRates',
        [
            {
                name: 'ItemID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'BUSINESSUNIT',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Commodity',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'FreightRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FreightUOM',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'ItemShipRateID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'PackageUOM',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Currency',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PackageRate',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            ...dateTimeSetupFields
        ],
        {}, //options
        (data) => {
            console.log('Item Shipping Rates REDUCER-- ', data);
        }
    );
}

const ItemShippingRates = {
    configureSetupCall
};

export default ItemShippingRates;
