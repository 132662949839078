import { SET_CUSTOMER_CATEGORY_DATA, CLEAR_CUSTOMER_CATEGORY_DATA } from '../actions/CustomerCategoryAction';

const initialState = {
    records: [],
    totalRecords: 0
};

const CustomerCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUSTOMER_CATEGORY_DATA:{
            let records = [];
            records = action.payload.Results;

            return Object.assign({}, state, {
                records,
                totalRecords: action.payload.totalCount
            });
        }
        case CLEAR_CUSTOMER_CATEGORY_DATA:
            return Object.assign({}, state, {
                records: [],
                totalRecords: 0
            });

        default:
            return state;
    }
};

export default CustomerCategoryReducer;
