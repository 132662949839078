import {
    SET_WEEKLY_SETTLEMENT_STATUS_DATA,
    CLEAR_WEEKLY_SETTLEMENT_STATUS_DATA,
    SET_SELECTED_POOL_WEEk,
    SET_SELECTED_SETTLEMENT_RECORD,
    SET_POOLWEEK_FREEZE_DATA
} from '../actions/WeeklySettlementStatusAction';

const initialState = {
    records: [],
    totalRecords: 0,
    selectedPoolWeek: {},
    selectedSettlementRecord: {},
    poolweekFreezeData: {}
};

const WeeklySettlementStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WEEKLY_SETTLEMENT_STATUS_DATA: {
            let records = [];
            records = action.payload.Data;
            return Object.assign({}, state, {
                records,
                totalRecords: action.payload.TotalCount
            });
        }
        case CLEAR_WEEKLY_SETTLEMENT_STATUS_DATA:
            return Object.assign({}, state, {
                records: [],
                totalRecords: 0
            });

        case SET_SELECTED_POOL_WEEk:
            return Object.assign({}, state, {
                selectedPoolWeek: action.payload
            });

        case SET_SELECTED_SETTLEMENT_RECORD:
            return Object.assign({}, state, {
                selectedSettlementRecord: action.payload
            });

        case SET_POOLWEEK_FREEZE_DATA:
            return Object.assign({}, state, {
                poolweekFreezeData: action.payload
            });

        default:
            return state;
    }
};

export default WeeklySettlementStatusReducer;
