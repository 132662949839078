import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'InventoryOnHand',
        [
            {
                name: 'ItemID',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'PreferredVendor',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'BusinessUnit',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'OnHand',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'Description',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TransactionKey',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'LastModifiedDate',
                type: FIELD_UNITS.DATE
            },
            {
                name: 'PoolWeek',
                type: FIELD_UNITS.RAW
            }
        ],
        { isVerbose: true }, //options
        (data) => {
            console.log('Inventory On Hand REDUCER-- ', data);
        }
    );
}

const InventoryOnHand = {
    configureSetupCall
};

export default InventoryOnHand;
