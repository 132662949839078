import { FIELD_UNITS, SetupCall } from '@driscollsinc/driscolls-display-rules';

function configureSetupCall() {
    SetupCall(
        'ReceiptByGrower',
        [
            {
                name: 'Category',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'GrowerID',
                type: FIELD_UNITS.RAW
            },
            {
                name: 'GrowerName',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'CostCenter',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Application',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'Location',
                type: FIELD_UNITS.STRING
            },
            {
                name: 'TotalTraysReceived',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PreliminarySHBooking',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FinalSHBooking',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PreliminaryGrowerBooking',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FinalGrowerBooking',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'PreliminaryFamilyEntityBooking',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            {
                name: 'FinalFamilyEntityBooking',
                type: FIELD_UNITS.NUMBER,
                options: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            }
        ],
        {}, //options
        (data) => {
            console.log('REDUCER-- ', data);
        }
    );
}

const ReceiptByGrower = {
    configureSetupCall
};

export default ReceiptByGrower;
