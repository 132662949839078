import { SET_FILTER_ID } from '../actions/SettlementWorkBenchDetailsAction';

const initialState = {
    filteredId: ''
};

const SettlementWorkBenchDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER_ID: {
            return Object.assign({}, state, {
                filteredId: action.payload
            });
        }

        default:
            return state;
    }
};

export default SettlementWorkBenchDetailsReducer;
