import { VENDOR_CODE_OPTIONS, PARENT_VENDOR_CODE_OPTIONS } from '../actions/VendorCodeActions';

const initialState = {
    vendorCodeOptions: [],
    parentVendorCodeOptions: []
};

const vendorCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case VENDOR_CODE_OPTIONS: {
            return Object.assign({}, state, {
                vendorCodeOptions: action.payload
            });
        }
        case PARENT_VENDOR_CODE_OPTIONS: {
            return Object.assign({}, state, {
                parentVendorCodeOptions: action.payload
            });
        }

        default:
            return state;
    }
};

export default vendorCodeReducer;
