import { SET_FILTERS, SET_ALL_FILTERS, CLEAR_FILTERS, CLEAR_ONE_FILTER, SET_DATA_SETUP_FILTER } from '../actions/FilterActions';
import FilterUtilities from '../utils/filterUtility';

const initialState = {
    selectedFilters: FilterUtilities.DefaultFilters,
    filters: []
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS:
            if (
                state.selectedFilters[action.payload.name] == null ||
                state.selectedFilters[action.payload.name].value !== action.payload.option.value ||
                state.selectedFilters[action.payload.name].isSet !== action.payload.option.isSet
            ) {
                return {
                    ...state,
                    selectedFilters: {
                        ...state.selectedFilters,
                        [action.payload.name]: action.payload.option
                    }
                };
            }
            return state;
        case SET_ALL_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload.filters
            };
        case CLEAR_FILTERS:
            return {
                ...state,
                selectedFilters: FilterUtilities.EmptyFilters
            };
        case CLEAR_ONE_FILTER:
            var selectedFilters = {};

            Object.entries(state.selectedFilters).forEach((filter) => {
                if (state.selectedFilters[action.payload.filter].index < filter[1].index) {
                    selectedFilters[filter[0]] = { ...filter[1], index: filter[1].index - 1 };
                } else {
                    selectedFilters[filter[0]] = filter[1];
                }
            });

            return {
                ...state,
                selectedFilters: {
                    ...selectedFilters,
                    [action.payload.filter]: FilterUtilities.EmptyFilters[action.payload.filter]
                }
            };
        case SET_DATA_SETUP_FILTER:
            return Object.assign({}, state, {
                filters: action.payload
            });
        default:
            return state;
    }
};

export default filterReducer;
